import React, { useEffect, useState } from 'react';
import { getCaller, updateCaller } from '../../../services/api';
import '../downline.css';
import FancyAdd from './FancyAdd';
import { motion } from "framer-motion";
import Button from '../../../component/button/Button';
import ToogleButton from '../../../component/toggleButton/ToogleButton';
import { useLocation  } from 'react-router-dom';
import getLocalData from '../../../utilities/LocalStorageData.utilities';
import { showToast } from '../../../utilities/toastHelper';

const FancyResultMaker = ({ susTime, event, sport, setMarketOpen, userName, uid, fancyMarketType }) => {
  const [fancyAddComponents, setFancyAddComponents] = useState([]);
  const [defaultLimits, setDefaultLimits] = useState({});
  const [agentDetails, setAgentDetails] = useState({});
  const { state } = useLocation()
  const updatePassData = state?.name ?? {};
  const [suspend, setSuspend] = useState(0);
  const [scope,setScope] = useState({});

  useEffect(() => {
    const {markets, result} = getLocalData('scope');
    setScope({markets, result});
  }, []);

  useEffect(() => {
    (async() =>{
      let limits = (await getCaller(`api/v1/events/${event.event_id}/limits/fancy`)).data;
      setDefaultLimits(limits[fancyMarketType]);
    })();
  }, [event.event_id]);

  useEffect(() => {
    const getAgentDetails = async () => {
      try {
        let agentData = await getCaller(`api/v1/users/${updatePassData.id}`);
        setAgentDetails(agentData?.data);
        setSuspend(agentData?.data?.is_suspend_all);
      } catch (error) {
      }
    };
    getAgentDetails();
  }, []);
 

  const addFancyAdd = async () => {
    setFancyAddComponents((prevFancyAddComponents) => [
      ...prevFancyAddComponents,
      <FancyAdd defaultLimits={defaultLimits} susTime={susTime} key={prevFancyAddComponents.length}  marketType={fancyMarketType} uid={uid} userName={userName} sport={sport} event={event} suspend={suspend} />,
    ]);
  };

  const handleSuspendAll = async (field, id, status) => {
    let updateResponse = await updateCaller(`api/v1/users/updateUser/${id}`, { [field]: status })
    let message = updateResponse.success ? status ? 'Enabled' : 'Disabled' : 'Failed!';

    if(updateResponse.success ){
      setSuspend(status);
      if(event?.event_id){
        let updateFancyResponse = await updateCaller(`api/v1/fancies/updateFancy/${event.event_id}/${id}`, { 'is_sus': status });
        if(updateFancyResponse.success){
          setFancyAddComponents((prevFancyAddComponents) => 
            prevFancyAddComponents.map((e,index) => (
              <FancyAdd key={index}  defaultLimits={e.props.defaultLimits} susTime={e.props.susTime}   marketType={e.props.marketType} uid={e.props.uid} userName={e.props.userName} sport={e.props.sport} event={e.props.event}  suspend= {status}  />
            ))
        );
          message = status ? 'Enabled' : 'Disabled';
        } else {
          message = "Failed"
        }
        showToast(message);
      } else {
        showToast(message, true)
      }
    }
  }

  return (
    <motion.div className='book-maker-market' initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.8, delay: 0.5, ease: [0, 0.71, 0.2, 1.01] }}>
      <div className="book-maker-heading">
        <p> FANCY RESULT DECLARE </p>
        <div className='remove-market-container'>
          <Button className='cancel' name='Remove Market' type='button' onClick={() => { setMarketOpen(false) }} />
        </div>
      </div>

      <form >
        <div className="book-maker-content">
          <div className="book-maker-select">
            <label htmlFor="fancy"> Select Fancy </label> <br />
            <select name="" id="fancy" className='fancy-result-input'><option> Select Fancy</option></select>
          </div>

          <div className="book-maker-select">
            <label htmlFor="fancy-result"> Select Result </label> <br />
            <select name="result" id="fancy-result" className='fancy-result-input'><option value=""> Select Result</option></select>
          </div>

          <div className="book-maker-btn" style={{ marginTop: "1.8rem" }}>
            <Button type='submit' disabled={true} className='submit' name="Submit Result" />
          </div>
        </div>
      </form>

      <div className="book-maker-match">
        <div> <p> {fancyMarketType.split("_").join(" ").toUpperCase()} <span className='match-name'> {event.event_name} </span>  </p> </div>
        <div className="book-input-submit-1">
          <div className='book-input'></div>
          <div className="toggle-password">
            <div className="suspend-container">
              <div className="suspend-container-body">
                <p className="suspend-para"> Suspend All</p>
                <ToogleButton defaultChecked={agentDetails.is_suspend_all} onChange={(value) => handleSuspendAll("is_suspend_all", agentDetails.id, value ? 1 : 0)}/>
              </div>
            </div>
          </div>
          <div>
            <Button className='submit' disabled={!scope?.markets?.add} type="button" name="ADD FANCY" onClick={ addFancyAdd } />
          </div>
        </div>
      </div>

      <div className="market-match-container" style={{ overflowX: 'auto' }}>
        {fancyAddComponents?.map((component, index) => (
          <div key={index}>{component}</div>
        ))}
      </div>
    </motion.div>
  )
};

export default FancyResultMaker;