import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { getCaller, postCaller } from '../../services/api';
import '../addEvent/addevent.css'
import ErrorBoundary from '../../error/ErrorBoundary';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import { motion } from "framer-motion";
import 'react-dates/lib/css/_datepicker.css';
import Button from '../../component/button/Button';
import Select from 'react-select';
import { showConfirmationDialog, showSuccessMessage, showToast } from '../../utilities/toastHelper';
const AddRacingEvent = () => {
    const [countryList, setCountryList] = useState([]);
    const [checked, setChecked] = useState([]);
    const [venueList, setvenueList] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(1, 'days'));
    const [endDate, setEndDate] = useState(moment().subtract(-5, 'days'));
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState("")
    const [venue, setVenue] = useState("");
    const [eventList, setEventList] = useState([])
    const [loading, setLoading] = useState(false)
    const [sportList, setSportList] = useState([]);
    const [sport, setSport] = useState("")
    const [checkError, setCheckError] = useState('')

    let minDate = moment().subtract(2, 'days').toDate();
    let maxDate = moment().subtract(-5, 'days').toDate();

    function handleDate(date) {
        let day = date.getDate() >= 9 ? date.getDate() : `0${date.getDate()}`
        let month = date.getMonth() >= 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
        return `${month}/${day}/${date.getFullYear()}`
    }

    useEffect(() => {
        async function getSport() {
            let sportResponse = await getCaller('api/v1/racing/sports');
            setSportList(sportResponse?.data)
        }
        getSport()
    }, [])

    async function handleSport(event) {
        setSport(event.value);
        setSelectedCountry("");
        setVenue("");
        setCountryList([]);
        setvenueList([]);
        setEventList([]);
        setChecked([]);

        if (!event.value) {
            return;
        } else {
            await getCountryList(event.value)
        }
    }


    async function getCountryList(sportId) {
        try {
            if (startDate && endDate && sportId) {
                let data = {
                    from: handleDate(startDate._d),
                    to: handleDate(endDate._d),
                    sportId
                }
                let response = await postCaller(`api/v1/racing/events/countrylist`, data);
                setCountryList(response?.data.filter((el) => el.marketCount !== 0))
            }
        } catch (error) {
            setCountryList([])
        }

    }
    async function handleVenue(selectedOption) {
        setSelectedCountry(selectedOption);
        setVenue("");
        setvenueList([]);
        setEventList([]);
        setChecked([]);
        if (selectedOption.value === "") {
            return;
        } else {
            
            await getVenueList(selectedOption.value);
        }
    }
    async function getVenueList(selectedCountry) {
        try {
            let data = {
                from: handleDate(startDate._d),
                to: handleDate(endDate._d),
                countryCode: selectedCountry,
                sportId: sport
            }
            const leagueResponse = await postCaller(`api/v1/racing/events/venuelist`, data);
            setvenueList(leagueResponse.data)
        } catch (error) {
            setvenueList([])
        }
    }
    async function handleLeague(selectedOption) {
        
        setVenue(selectedOption);
        if (selectedOption.value === "") {
            return;
        } else {
            await getEvents(selectedOption.value);
        }
    }


    async function getEvents(selectedVenue) {
        try {
            setChecked([]);
            let leagueData = {
                from: handleDate(startDate._d),
                to: handleDate(endDate._d),
                countryCode: selectedCountry.value,
                venue: selectedVenue,
                sportId: sport
            }
            setLoading(true);

            const eventResponse = await postCaller(`api/v1/racing/events`, leagueData);
            setEventList(eventResponse.data)
            setChecked(eventResponse.data.map(e => parseInt(e.event.id)))
            if (eventResponse.success === true) {
                setLoading(false);
            }
            else {
                showToast(eventResponse.message, true)
            }
            setLoading(false);
        } catch (err) {
            console.error(err);
        }
    }
    const handleMatchCheck = (event, value) => {
        let updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, parseInt(event.target.value)];
        } else {
            updatedList.splice(checked.indexOf(parseInt(event.target.value)), 1);
        }
        setChecked(updatedList);
    };

    const handleAddMatch = async (event) => {
        event.preventDefault()
        if (!checked.length){
            setCheckError('Please Checked');
            return false
        }
        let body = {
            from: handleDate(startDate._d),
            to: handleDate(endDate._d),
            events: checked ?? []
        }
        
        await showConfirmationDialog(`You want to Add these Matches?`)
        .then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const res = await postCaller(`api/v1/racing/events/management/addEvents?countryCode=${selectedCountry.value}&venue=${venue.value}&sportId=${sport}`, body)
                if (res.success) {
                    showSuccessMessage('Your Match Added Successfully')
                    setSport("");
                    setSelectedCountry("");
                    setVenue("");
                    setLoading(false);
                    setChecked([]);
                    setEventList([])
                }

            }
        }).catch(err => console.error(err))
    }

    return (
            <motion.div className="wrapper" initial={{ opacity: 0, x: '100vh' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}>
                <div className="wrapper-head">
                    <h3> ADD RACING EVENTS </h3>
                </div>
                <div className="result-sport-container">
                    <div className="result-sport-content">
                        <div className="select-container">
                            <label htmlFor="date" id="date-label"> Select Date </label> <br />
                            <DateRangePicker
                                startDate={startDate}
                                startDateId="s_id"
                                endDate={endDate}
                                endDateId="e_id"
                                onDatesChange={({ startDate, endDate }) => { 
                                    setStartDate(startDate); 
                                    setEndDate(endDate);
                                    setSelectedCountry("");
                                    setVenue("");
                                    setSport("");
                                    setCountryList([]);
                                    setvenueList([]);
                                    setEventList([]);
                                    setChecked([]);
                                }}
                                focusedInput={focusedInput}
                                onFocusChange={e => setFocusedInput(e)}
                                displayFormat="DD/MM/YYYY"
                                isOutsideRange={(d) => maxDate < d.toDate()}
                                isDayBlocked={(d) => d.toDate() < minDate}
                                minimumNights={0}
                                block
                                showClearDates
                                readOnly
                            />
                        </div>
                        <div className="select-container">
                            <label htmlFor="sport"> Select Racing Sport </label> <br />
                            <Select
                                value = {sport ? { value: sport, label: sportList.find(e=> e.id === sport)?.name} : null}
                                onChange={async (selectedOption) => handleSport(selectedOption)}
                                options={sportList.map((el) => ({ value: el.id, label: el.name }))}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        height: '47px',
                                    }),
                                }}
                                placeholder="Select Racing Sport"
                            />
                        </div>
                        <div className="select-container">
                            <label htmlFor="selectedCountry">Select Country</label> <br />
                            <Select
                                id="selectedCountry"
                                value={selectedCountry}
                                onChange={handleVenue}
                                options={countryList.map(country => ({
                                    value: country.countryCode,
                                    label: country.countryCode
                                }))}
                                isSearchable={true}
                                placeholder="Select Country"
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        height: '47px',
                                    }),
                                }}
                            />

                        </div>
                        <div className="select-container">
                            <label htmlFor="venue"> Select Venue </label> <br />
                            <Select
                                id="venue" 
                                value={venue}
                                onChange={handleLeague}
                                options={venueList.map(el => ({
                                    value: el.venue,
                                    label: el.venue
                                }))}
                                isSearchable={true}
                                placeholder="Select Venue"
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        height: '47px',
                                    }),
                                }}
                            />

                        </div>
                    </div>
                </div>
                {
                    loading ? <div className="loader-wrapper">
                        <div className='loader'>
                        </div>
                    </div> : <div className="table-border">
                        {eventList && <div className="wrapper-body">
                            <div className='wrapper-content' >
                                <h2> Manage <span className='event-head'> { } </span> Matches <span className='ten'> {eventList.length > 0 ? eventList.length : 0} </span>
                                    <span className='zero'> {checked.length} </span> </h2>
                                {eventList?.length === 0 ? '' :
                                    <div className="">
                                        <Button type='button' name='Add Matches' className='green' onClick={handleAddMatch} disabled={checked.length === 0}/>
                                    </div>
                                }
                            </div>
                        </div>}
                        {eventList?.length === 0 && <ErrorBoundary />}
                        <table width="100%" style={{ overflowX: "auto" }}>
                            <tbody>
                                {eventList?.length > 0 &&
                                    eventList?.map(el =>
                                        <tr className='custom-row' key={el.event.id}>
                                            <td>
                                                <input type="checkbox"
                                                    checked={checked.includes(parseInt(el.event.id))}
                                                    value={el.event.id}
                                                    onChange={(event) => handleMatchCheck(event, el.id)} className='check-container' />
                                                {checked.length? null: (<span style={{ color: "red", display :"block"}}>{checkError}</span>)}
                                            </td>
                                            <td className='name-container' > {el.event.name} </td>
                                            <td className='date-container' >{(moment(el.event.openDate).format("DD/MM/YYYY hh:mm") )} </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                        {/* <Pagination page={page} setPage={setPage} maximo={maximo}/>    */}
                    </div>
                }

            </motion.div>

    )
}
export default AddRacingEvent