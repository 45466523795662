import React, {useEffect } from 'react'
import '../Login/login.css'
import logoimg from '../../assets/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../component/button/Button';
import { useState } from 'react';
import Swal from "sweetalert2";
const Login = () => {
  const navigate = useNavigate()
  const [loginStatus,setLoginStatus] = useState()
  const validationLogin = Yup.object().shape({
    user_id: Yup.string()
      .required('User id is Required'),
      password: Yup.string()
      .required('Password is Required'),
  });
  const formik = useFormik({
    initialValues: {
      user_id: "",
      password: "",
    },
    validationSchema:validationLogin,
    onSubmit: async(values) => {
      let url = `${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`
      const res = ( await fetch(url, {
        method: 'POST',
        headers: {
          "content-type": 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }, body: JSON.stringify(values)
      }));
      
      const data = await res.json();
      if (res.ok) {
        const successResponse = {
            success: true,
            message: data.message,
            data: data.data,
        };
        setLoginStatus(successResponse);

        localStorage.setItem('token', data.data.id);
        localStorage.setItem('user_role', data.data?.user_role);
        localStorage.setItem('user_name', data.data?.user_name);
        localStorage.setItem('user_sus', data.data?.sus);
        localStorage.setItem('scope', data.data?.scope);

        navigate('/dashboard');
      } else {
        const errorResponse = {
          success: false,
          errno: data.errno,
          message: data.message,
        };
        setLoginStatus(errorResponse);
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          background: 'red',
          color: 'white',
          showConfirmButton: false,
          timer: 2000,
          width: 450,
          padding: '.5rem'
      });

      Toast.fire({
          icon: 'error',
          iconColor: 'white',
          title: errorResponse.message,
      });
      }
    },
  });
 

  useEffect(() => {
    if (localStorage.getItem('token')) {
      if(loginStatus?.user_role?.toLowerCase()==='agent'){
        navigate('/agent/manage_market')
      }
      else{
        navigate('/dashboard')
      }
    }
  }, [loginStatus?.user_role, navigate])

  return (
    <>
      <div className="login-container">
        <div className="login-content">
          <div className="login-body">
            <div className="logo-img">
              <img src={logoimg} alt="" />
            </div>
            
            <form className='form-container' onSubmit={formik.handleSubmit}>
              <div className="form-body">
                <div className="">
                  <input type="text" name="user_id" placeholder='Username' id="userid" maxLength={30}
                    onChange={formik.handleChange}
                    value={formik.values.user_id}
                    onBlur={formik.handleBlur}
                    autoComplete={"off"}
                  />
                  {formik.errors.user_id && formik.touched.user_id && <span className="error" style={{ color: "red" }}>
                    {formik.errors.user_id}
                  </span>}
                  <input autoComplete="off" type="password" name="password" placeholder='Password' id="password" maxLength={30}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                  />
                   {formik.errors.password && formik.touched.password && <span className="error" style={{ color: "red" }}>
                    {formik.errors.password}
                  </span>}
                  </div>
                  <div className="login-btn">
                  <Button type={'submit'}  name={"Login"}  />
                  </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login