import React, { useEffect, useState } from 'react'
import '../eventManage/eventmanage.css'
import { RiFootballFill } from 'react-icons/ri'
import { getCaller } from '../../services/api'
import ErrorBoundary from '../../error/ErrorBoundary'
import LeagueSettingsDetails from './LeagueSettingsDetails'
const LeagueManagement = () => {
  const [eventTab, setEventTab] = useState(false)
  const [getSportData, setGetSportData] = useState([]);
  const [leagueData, setLeagueData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAllData = async () => {
      const res = await getCaller('api/v1/sports/management/getSport');
      const cricketSport = res?.data.find(sport => sport.slug === 'cricket');
      
      if (cricketSport) {
        const reorderedData = [
          cricketSport,
          ...(res?.data?.filter(sport => sport.slug !== 'cricket') || [])
        ];        
        setGetSportData(reorderedData);
        await getLeagueFromSport(cricketSport);
      } else {
        setGetSportData(res?.data);
        await getLeagueFromSport(res?.data[0]);
      }
      setLoading(false);
    }
    getAllData();
  }, []);

  const addNewFields = (dataArray) => {
    dataArray.forEach((data) => {
      data.bookmaker_limits = {
        inplay_stake_limit: "0",
        inplay_max_market_limit: "0",
        inplay_max_odd_limit: "0",
        odd_limit: "0",
        ...data.bookmaker_limits
      };
      Object.entries(data.fancy_limits).forEach(([ key, value ]) => {
        data.fancy_limits[key] = {
          inplay_stake_limit: "0",
          inplay_max_market_limit: "0",
          inplay_max_odd_limit: "0",
          odd_limit: "0",
          ...value
        }
      });
      Object.entries(data.market_limits).forEach(([ key, value ]) => {
        data.market_limits[key] = {
          inplay_max_market_limit: "0",
          inplay_max_odd_limit: "0",
          ...value
        }
      });
    })
    return dataArray;
  }

  const getLeagueFromSport = async (e ) => {
    setLoading(true);
    const res = await getCaller(`api/v1/leagues/leaguesFindById?id=${e?.id}`);
    setLeagueData(addNewFields(res.data));
    setEventTab(e.id);
    setLoading(false);
  };

  return (
      <div className="wrapper" >
        <div className="wrapper-head">
          <h3>LEAGUES MANAGEMENTS</h3>
        </div>
        <div className="event-container">
          {
            getSportData?.length ? getSportData
            ?.map((e) => (
              <div key={e.id} className={`event-tab ${eventTab === e.id ? 'active-event-tab' : null} `}
                onClick={()=>getLeagueFromSport(e)}>
                <div className="event-body">
                  <div>
                    <RiFootballFill style={{ fontSize: "1.5rem" }} />
                    <p>{e?.name}</p>
                  </div>
                </div>
              </div>
            )):<ErrorBoundary/>
          }
        </div>
        <div className="event-content" >
          {
            loading ?
              <div className="loader-wrapper">
                <div className='loader'>
              </div>
              </div> : <LeagueSettingsDetails  leagueData={leagueData} setLeagueData={setLeagueData}  />
          }
        </div>
      </div>
  )
}


export default LeagueManagement;