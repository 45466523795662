import React,{useState, useEffect} from 'react'
import {  postCaller ,deleteCaller, updateCaller } from '../../../services/api';
import {MdDelete ,MdPostAdd} from 'react-icons/md'
import  {IoMdSave}  from 'react-icons/io'
import '../downline.css'
import ToogleButton from '../../../component/toggleButton/ToogleButton';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import getLocalData from '../../../utilities/LocalStorageData.utilities';
import FancyAssignAddOdds from './FancyAssignAddOdds';
import Button from '../../../component/button/Button';
import moment from 'moment-timezone';
import { showConfirmationDialog, showDeleteMessage, showSuccessMessage, showToast } from '../../../utilities/toastHelper';

const FancyAssignAdd = ({defaultLimits, userName}) => {
    const [formMode, setFormMode] = useState('Add');
    const [fancyID, setFancyID] = useState(null);
    const [allowAddBookmaker, setAllowAddBookmaker] = useState(true);
    const [fancyAddOddsComponents, setFancyAddOddsComponents] = useState([]);

    const generateInitialOdds = (market_type) => {
        const timestampInSeconds = new Date().getTime();
        let selectionID = timestampInSeconds / 1000 + Math.floor(Math.random() *1000);;
        if (market_type === "khado_markets") {
            return {
                size: '',
            };
        } else if (market_type === "odd_even_markets") {
            return {
                back:'',
                lay: '',
            };
        } else if (market_type === "multi_runner_markets") {
            return [{
                selection_name: '',
                runNo: '',
                runYes: '',
                oddsNo: '',
                oddsYes: '',
                suspend: 0,
                si:parseInt(selectionID)
            }];
        } else {
            return {
                runNo: '',
                runYes: '',
                oddsNo: '',
                oddsYes: '',
            }
        }
    };

    const [fancyData, setFancyData] = useState({
        event_id: userName.event_id,
        match_id: userName.match_id,
        sport_id: userName.sport_id,
        market_type: userName.market_id,
        agent_id: userName.id,
        is_sus: 0,
        is_active: 0,
        is_com: defaultLimits?.is_com || 0,
        msg: '',
        min_stake: defaultLimits?.min_stake || 100,
        max_stake: defaultLimits?.stake_limit || '',
        bet_delay: parseInt(defaultLimits?.delay) || '',
        max_market_limit: defaultLimits?.max_market_limit || '',
        market_name: '',
        odds: generateInitialOdds(userName.market_id),
        sus_time: userName.sus_time,
        has_multi_runners: userName.market_id === "multi_runner_markets" ? 1 : 0
    });

    const [endDate, setEndDate] = useState(null);
    const [scope, setScope] = useState({});
    const [isAdded,setisAdded] = useState(false);
    
    useEffect(() => {
        const {markets, result} = getLocalData('scope');
        setScope({markets, result})
    }, []);

    const handleEndDateChange = (date) => {
        setEndDate(date);
        setFancyData({ ...fancyData, end_date: date });
    };

    const handleSubmit = async () => {
        if (formMode === 'Add') {
            const response = await postCaller('api/v1/fancies', {
                event_id:userName.event_id,
                match_id:userName.match_id,
                sport_id: userName.sport_id,
                market_type:userName.market_id,
                agent_id: userName.id,
                is_sus: fancyData.is_sus,
                is_active: fancyData.is_active,
                is_com: fancyData.is_com,
                msg: fancyData.msg,
                min_stake: fancyData.min_stake,
                max_stake: fancyData.max_stake,
                bet_delay: fancyData.bet_delay,
                max_market_limit: fancyData.max_market_limit,
                market_name: fancyData.market_name,
                sus_time: userName.sus_time,
                odds:fancyData.odds,
                end_date: fancyData.end_date,
                has_multi_runners: fancyData.has_multi_runners
            });
            if(response.success === true){
                showToast("Fancy Added Successfully.");
                const fancyId = response.data.fancyID;
                setFancyID(fancyId);
                setFormMode('Update');
            } else if (response.success === false) {
                showToast(response.message, true);
                setFormMode('Add');
            }
            
        } else if (formMode === 'Update'  ) {
            const response = await updateCaller(`api/v1/fancies/${fancyID}`, {
                is_sus: fancyData.is_sus,
                is_active: fancyData.is_active,
                is_com: fancyData.is_com,
                msg: fancyData.msg,
                min_stake: fancyData.min_stake,
                max_stake: fancyData.max_stake,
                bet_delay: fancyData.bet_delay,
                max_market_limit: fancyData.max_market_limit,
                market_name: fancyData.market_name,
                odds:fancyData.odds,
                end_date: fancyData.end_date
            })
            if(response.success === true){
                showToast("Fancy Updated Successfully.");
            }
        }
        setisAdded(true);
    };

    const handleDelete = async(item) => {
        !isAdded && setisAdded(false);

        if (!fancyID) {
            showDeleteMessage("Fancy Table Deleted successfully")
            setAllowAddBookmaker(false)
            return
        }
        
        await showConfirmationDialog( `You are about to delete a fancy.`)
        .then(async (result) => {
            if (result.isConfirmed){
                const deleteResponse =await deleteCaller(`api/v1/fancies/${fancyID}`)
                if(deleteResponse.success===true){
                    showSuccessMessage('your fancy Deleted Successfully')
                    setAllowAddBookmaker(false)
                }
            }
        })
    };

    const handleOddsInputChange = (oddsIndex, field, value) => {
        const updatedOdds = [...fancyData.odds]; 
        const inputValue = field === 'selection_name' ? value : value;
      
        if ( field === 'selection_name' ||  inputValue >= 0) {
            if (!updatedOdds[oddsIndex]) {
                updatedOdds[oddsIndex] = {};
            }
            updatedOdds[oddsIndex][field] = inputValue;
            setFancyData({ ...fancyData, odds: updatedOdds });
        }

        if (field === 'suspend') {
            const message = value === 1 ? "Suspend Enabled" : "Suspend Disabled";
            showToast(message);
        }
    };

    const addFancyOdds = (index) => {
        const timestampInSeconds = new Date().getTime();
        const selectionID = timestampInSeconds / 1000;

        setFancyAddOddsComponents((prevData) => ({
            ...prevData,
            [index]: [
                ...(prevData[index] || []),
                <FancyAssignAddOdds
                    key={prevData[index] ? prevData[index].length : 0}
                    market_type={fancyData.market_type}
                    si={parseInt(selectionID)}
                    onAddOdds={(newOdds) => handleAddOdds(index, newOdds)}
                    onRemoveOdds={() => removeFancyOdds(index, prevData[index] ? prevData[index].length : 0)}
                />
            ]
        }));
    };

    const removeFancyOdds = async(index, key) => {
        setFancyAddOddsComponents((prevData) => {
            const updatedData = { ...prevData };
            updatedData[index] = updatedData[index].filter((_, i) => i !== key);
            return updatedData;
        });
    };

    const handleAddOdds = async(index, newOdds) => {
        const updatedFancyData = { ...fancyData };
        updatedFancyData.odds = [...updatedFancyData.odds];
        updatedFancyData.odds.push(newOdds);
        setFancyData(updatedFancyData);
        const formattedEndDate = endDate ? moment(endDate).utc().format('YYYY-MM-DD HH:mm:ss') : null;
        let response = await updateCaller(`api/v1/fancies/${fancyID}`,{
            is_sus: updatedFancyData.is_sus,
            is_active: updatedFancyData.is_active,
            is_com: updatedFancyData.is_com,
            msg: updatedFancyData.msg,
            min_stake: updatedFancyData.min_stake,
            max_stake: updatedFancyData.max_stake,
            bet_delay: updatedFancyData.bet_delay,
            max_market_limit: updatedFancyData.max_market_limit,
            market_name: updatedFancyData.market_name,
            odds:updatedFancyData.odds,
            end_date: formattedEndDate,
            sus_time: userName.susTime
        })
        if(response.success === true){
            showToast("Fancy Updated Successfully.");
        }
    };
    const handleInputChange = (field, value) => {
        setFancyData((prevData) => ({
            ...prevData,
            odds: {
                ...prevData.odds,
                [field]: field === 'oddsNo' && value < 0 ? prevData.odds.oddsYes : value,
            },
        }));
    };
    
    const handleOddsChange = (field, value) => {
        setFancyData((prevData) => ({
            ...prevData,
            odds: {
                ...prevData.odds,
                [field]: field === 'oddsYes' && value < 0 ? prevData.odds.oddsYes : value,
            },
        }));
    };
    
    return (
        <div>
        { allowAddBookmaker && !["multi_runner_markets"].includes(fancyData.market_type) ?
            <div>
                <table width="100%">
                    <thead>
                        <tr  id='fancy-head-coloumn'>
                            <th> SELECTION </th>
                            <th> STAKE </th>
                            <th> DELAY </th>
                            <th> END DATE </th>
                            {!["khado_markets", "odd_even_markets"].includes(fancyData.market_type) && (
                                <>
                                    <th>NO (OUTCOME)</th>
                                    <th>YES (OUTCOME)</th>
                                    <th>NO (ODDS)</th>
                                    <th>YES (ODDS)</th>
                                </>
                            )} 
                            {fancyData.market_type === "khado_markets" && <th>SIZE</th>}
    
                            {fancyData.market_type === "odd_even_markets" && (
                                <>
                                    <th>BACK</th>
                                    <th>LAY</th>
                                </>
                            )}
                            <th> SUSPEND </th>
                            <th> COMM </th>
                            <th> ACTIVE </th>
                            <th> ACTIONS </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className='fancy-head-row'>
                            <td >
                                <input type="text" autoComplete="off" name="marketName" className='fancy-stake-input' value={fancyData.market_name} onChange={(e) => setFancyData({ ...fancyData, market_name: e.target.value })} placeholder='Fancy Name' /> <br />
                                <input type="text" autoComplete="off" name="msg"  className='fancy-stake-input' value={fancyData.msg} onChange={(e) => setFancyData({ ...fancyData, msg: e.target.value })} placeholder='Message' />
                            </td>
    
                            <td >
                                <input type="number" autoComplete="off" name="min_stake" className='fancy-stake-input 'value={fancyData.min_stake} onChange={(e) =>{  const value = e.target.value;
                                if (value >= 0) {
                                    setFancyData({ ...fancyData, min_stake: value });
                                }}} placeholder="Min Stake"  disabled/> <br />
    
                                <input type="number" autoComplete="off" name="max_stake" className='fancy-stake-input' value={fancyData.max_stake} onChange={(e) => {  const value = e.target.value;
                                if (value >= 0) {
                                    setFancyData({ ...fancyData, max_stake: value });
                                }}} placeholder='Max Stake' size="5" />
                            </td>
    
                            <td >
                                <input type="number" autoComplete="off" name="Bet_Delay "  className='fancy-stake-input' value={fancyData.bet_delay} onChange={(e) => {  const value = e.target.value;
                                if (value >= 0) {
                                    setFancyData({ ...fancyData, bet_delay: value });
                                }}} placeholder='Bet Delay ' /> <br />
    
                                <input type="number" autoComplete="off" name="max_market_limit"  className='fancy-stake-input' value={fancyData.max_market_limit} onChange={(e) => {  const value = e.target.value;
                                if (value >= 0) {
                                    setFancyData({ ...fancyData, max_market_limit: value });
                                }}} placeholder={'max_market_limit'} />
                            </td>
                            <td>
                            <DatePicker
                                name="end_date"
                                selected={endDate}
                                onChange={handleEndDateChange}
                                showTimeSelect
                                timeFormat="HH:mm"
                                placeholderText="End Date & Time"
                                timeIntervals={15}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                className='fancy-stake-input'
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                            /> <br />
                            </td>
                            {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (   <td >
                                <input type="number" autoComplete="off" className='fancy-input' value={fancyData.odds.runNo} onChange={(e) => {  const value = e.target.value;
                                if (value >= 0) {
                                    handleOddsChange('runNo', value);
                                }}} name="runNo" />
                            </td>)}

                            {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (  <td >
                                <input type="number" autoComplete="off" name="runYes" className='fancy-input' value={fancyData.odds.runYes}  onChange={(e) => {  const value = e.target.value;
                                if (value >= 0) {
                                    handleOddsChange('runYes', value);
                                }}}/>
                            </td>)}

                            {!["khado_markets"].includes(fancyData.market_type) ? null : (  <td >
                                <input type="number" autoComplete="off" name="size" className='fancy-input' value={fancyData.odds.size}  onChange={(e) => {  const value = e.target.value;
                                if (value >= 0) {
                                    handleOddsChange('size', value);
                                }}}/>
                            </td>)}

                            {!["odd_even_markets"].includes(fancyData.market_type) ? null : (  <td >
                                <input type="number" autoComplete="off" name="back" className='fancy-input' value={fancyData.odds.back}  onChange={(e) => {  const value = e.target.value;
                                if (value >= 0) {
                                    handleOddsChange('back', value);
                                }}}/>
                            </td>)}

                            {!["odd_even_markets"].includes(fancyData.market_type) ? null : (  <td >
                                <input type="number" autoComplete="off" name="lay" className='fancy-input' value={fancyData.odds.lay}  onChange={(e) => {  const value = e.target.value;
                                if (value >= 0) {
                                    handleOddsChange('lay', value);
                                }}}/>
                            </td>)}
                            
                            {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (   <td >
                                <input type="number" autoComplete="off" name="oddsNo" className='fancy-input' value={fancyData.odds.oddsNo}
                                onChange={(e) => handleInputChange('oddsNo', e.target.value)}
                                />
                            </td>)}

                            {["khado_markets", "odd_even_markets"].includes(fancyData.market_type) ? null : (  <td >
                                <input type="number" autoComplete="off" name="oddsYes" className=' fancy-input' value={fancyData.odds.oddsYes}
                                onChange={(e) => handleOddsChange('oddsYes', e.target.value)}
                                />
                            </td>)}
                            <td >
                                <div className="toggle-password">
                                <ToogleButton  defaultChecked={fancyData.is_sus===1}  value={fancyData.is_sus} onChange={(e) => setFancyData({ ...fancyData, is_sus: e ? 1 : 0 })} />
                            </div>
                            </td>
                            <td>
                                <div className="toggle-password">
                                <ToogleButton defaultChecked={fancyData.is_com===1}  value={fancyData.is_com} onChange={(e) => setFancyData({ ...fancyData, is_com: e ? 1 : 0 })} />
                                </div>
                            </td>
                            <td>
                                <div className="toggle-password">
                                <ToogleButton defaultChecked={fancyData.is_active===1}  value={fancyData.is_active} onChange={(e) => setFancyData({ ...fancyData, is_active: e ? 1 : 0 })} />
                                </div>
                            </td>
                            <td>
                            <div className="toggle-password">
                                <div className='actions_tab'>
                                    <div className='fancy-submit'>
                                    {formMode === 'Add' ? (
                                       <MdPostAdd className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }} />
                                    ) : (
                                        <IoMdSave tabIndex={0} className="icon" onClick={handleSubmit} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }} />
                                    )}
                                    </div>
                                    <div className='fancy-cancel'>
                                        <MdDelete tabIndex={0} className="icon" onClick={handleDelete} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleDelete() } }} />
                                    </div>
                                </div>
                            </div>
                            </td>
                            </tr>
                    </tbody>
                </table>
            </div> : null}
        <div>
            { 
                allowAddBookmaker && ["multi_runner_markets"].includes(fancyData.market_type) ?
                    <div>
                        <table width="100%">
                            <div>
                                <>
                                    <div className="market-book-maker-border">
                                        <div className='market-bookmaker-popup' style={{ overflowX: "auto" }}>
                                            <div className="">
                                                <label htmlFor="marketName">Market Name</label> <br />
                                                <input type="text" autoComplete="off" name="marketName" className='fancy-stake-input' value={fancyData.market_name} onChange={(e) => setFancyData({ ...fancyData, market_name: e.target.value })} placeholder='Fancy Selection Name' /> <br />
                                                
                                                <label htmlFor="stake_limit">Min Stake </label> <br />
                                                <input type="number" autoComplete="off" name="min_stake" className='fancy-stake-input 'value={fancyData.min_stake} onChange={(e) => setFancyData({ ...fancyData, min_stake: e.target.value })} placeholder="Min Stake" /> <br />
                                                
                                                <label htmlFor="stake_limit">MAx Stake </label> <br />
                                                <input type="number" autoComplete="off" name="max_stake" className='fancy-stake-input' value={fancyData.max_stake} onChange={(e) => setFancyData({ ...fancyData, max_stake: e.target.value })} placeholder='Max Stake' size="5" /><br />
                                                
                                                <label htmlFor="msg">Message</label> <br />
                                                <input type="text" autoComplete="off" name="msg"  className='fancy-stake-input' value={fancyData.msg} onChange={(e) => setFancyData({ ...fancyData, msg: e.target.value })} placeholder='Message' /><br />
                                                
                                                <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                                                <input type="number" autoComplete="off" name="max_market_limit"  className='fancy-stake-input' value={fancyData.max_market_limit} onChange={(e) => setFancyData({ ...fancyData, max_market_limit: e.target.value })} placeholder={'max_market_limit'} /><br />
                                                
                                                <label htmlFor="bet_delay">Bet delay</label> <br />
                                                <input type="number" autoComplete="off" name="Bet Delay"  className='fancy-stake-input' value={fancyData.bet_delay} onChange={(e) => setFancyData({ ...fancyData, bet_delay: e.target.value })} placeholder='Bet Delay' /> <br />
                                                
                                                <label htmlFor="bet_delay">End Date</label> <br />
                                                <DatePicker
                                                    name="end_date"
                                                    selected={endDate}
                                                    onChange={handleEndDateChange}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    placeholderText="End Date & Time"
                                                    timeIntervals={15}
                                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                                    className='fancy-stake-input'
                                                /><br />
                                            </div>
                    
                                            <div>
                                                <div className="suspend-container">
                                                    <div className="suspend-container-body"><p className='suspend-para'>Suspend</p><ToogleButton  defaultChecked={fancyData.is_sus === 1} value={fancyData.is_sus} 
                                                        onChange={(e) => {
                                                            const newValue = e ? 1 : 0;
                                                            setFancyData({ ...fancyData, is_sus: newValue });
                                                            showToast(newValue ? "Suspend Enabled" : "Suspend Disabled");
                                                        }} />
                                                    </div>

                                                    <div className="suspend-container-body"><p className='suspend-para'>Commission</p><ToogleButton defaultChecked={fancyData.is_com === 1} value={fancyData.is_com} 
                                                        onChange={(e) => {
                                                            const newValue = e ? 1 : 0;
                                                            setFancyData({ ...fancyData, is_com: newValue });
                                                            showToast(newValue ? "Commission Enabled" : "Commission Disabled");
                                                        }} />
                                                    </div>

                                                    <div className="suspend-container-body"><p className='suspend-para'>Active</p><ToogleButton defaultChecked={fancyData.is_active === 1} value={fancyData.is_active}
                                                        onChange={(e) => {
                                                            const newValue = e ? 1 : 0;
                                                            setFancyData({ ...fancyData, is_active: newValue });
                                                            showToast(newValue ? "Active Enabled" : "Active Disabled");
                                                        }} />
                                                    </div>

                                                    <div className="toggle-password">
                                                        <div className='actions_tab'>
                                                            <button className='fancy-submit' disabled={isAdded && !scope?.markets?.update}>
                                                                {
                                                                    formMode === 'Add' ? (
                                                                        <MdPostAdd className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }}/>
                                                                    ) : (
                                                                        <IoMdSave className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }}/>
                                                                    )
                                                                }
                                                            </button>
                                                            <button className='fancy-cancel' disabled={isAdded && !scope?.markets?.delete}>
                                                                <MdDelete className="icon" onClick={handleDelete} disabled={isAdded && !scope?.markets?.delete} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleDelete() } }}/>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="bookmaker-table-container" style={{ overflowX: "auto" }}>
                                        <div className="book-maker-match">
                                                <div className="book-input-submit-1" >
                                                    <div>
                                                        <Button className='submit' type={"button"} name={"ADD FANCY ODDS"}  onClick={() => addFancyOdds(0)}/>
                                                    </div>
                                                </div> 
                                            
                                            </div>
                                            <div>
                                                {Object.keys(fancyAddOddsComponents).map((key) => (
                                                    <div key={key}>
                                                        {fancyAddOddsComponents[key]?.map((component, idx) => (
                                                            <div key={idx}>{component}</div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                            <table width="100%">
                                                <thead id='fancy-head-coloumn'>
                                                    <tr className=''>
                                                        <th align='left'> SELECTION NAME </th>
                                                        {!["khado_markets", "odd_even_markets"].includes(fancyData.market_type) && (
                                                            <>
                                                                <th>NO (OUTCOME)</th>
                                                                <th>YES (OUTCOME)</th>
                                                                <th>NO (ODDS)</th>
                                                                <th>YES (ODDS)</th>
                                                            </>
                                                        )}
                                                        <th> SUSPEND </th>
                                                        <th> ACTION </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        fancyData.odds.map((oddsItem, oddsIndex) => (
                                                            <tr key={oddsIndex}>
                                                                <td>
                                                                    <input type="text" autoComplete="off"  name="marketName" className='fancy-stake-input'  value={oddsItem.selection_name || ''} 
                                                                        onChange={(e) => handleOddsInputChange(oddsIndex, 'selection_name', e.target.value)} placeholder='Fancy Selection Name'/>
                                                                </td>
                                                                {!["khado_markets", "odd_even_markets"].includes(fancyData.market_type) && (
                                                                    <>
                                                                        <td>
                                                                            <input type="number" autoComplete="off" name="runNo" className="fancy-input" value={oddsItem.runNo}onChange={(e) => handleOddsInputChange(oddsIndex, 'runNo', e.target.value)}/>
                                                                        </td>
                                                                        
                                                                        <td>
                                                                            <input type="number" autoComplete="off" name="runYes" className="fancy-input" value={oddsItem.runYes} onChange={(e) => handleOddsInputChange(oddsIndex, 'runYes', e.target.value)} />
                                                                        </td>
                                                                        
                                                                        <td>
                                                                            <input type="number" autoComplete="off"  name="oddsNo" className="fancy-input" value={oddsItem.oddsNo} onChange={(e) => handleOddsInputChange(oddsIndex, 'oddsNo', e.target.value)} />
                                                                        </td>
                                                                        
                                                                        <td>
                                                                            <input type="number" autoComplete="off" name="oddsYes" className="fancy-input" value={oddsItem.oddsYes} onChange={(e) => handleOddsInputChange(oddsIndex, 'oddsYes', e.target.value)} />
                                                                        </td>
                                                                    </>
                                                                )}

                                                                <td>
                                                                    <div className="toggle-password">
                                                                        <ToogleButton  defaultChecked={oddsItem.suspend===1}  value={oddsItem.suspend}  onChange={(e) => handleOddsInputChange(oddsIndex, 'suspend', e ? 1 : 0 )} />  
                                                                    </div>
                                                                </td>
                                                                
                                                                <td>
                                                                    <div className="toggle-password">
                                                                        <div className='actions_tab'>
                                                                            <button className='fancy-submit' disabled={isAdded && !scope?.markets?.update}>
                                                                                {
                                                                                    formMode === 'Add' ? (
                                                                                        <MdPostAdd className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }}/>
                                                                                    ) : (
                                                                                        <IoMdSave className="icon" onClick={handleSubmit} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleSubmit() } }}/>
                                                                                    )
                                                                                }
                                                                            </button>
                                                                            <button className='fancy-cancel' disabled={isAdded && !scope?.markets?.delete}>
                                                                                <MdDelete className="icon" onClick={handleDelete} disabled={isAdded && !scope?.markets?.delete} tabIndex={0} onKeyDown={(e) => { if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32){ e.preventDefault(); handleDelete() } }}/>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>  
                                        </div>
                                    </div>
                                </>
                            </div>
                        </table>
                    </div> 
                : null
            }
        </div>
        </div>
    )
};

export default FancyAssignAdd;