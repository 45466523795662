import React, { useState} from 'react'
import {MdDelete ,MdPostAdd} from 'react-icons/md'
import '../downline.css'
import ToogleButton from '../../../component/toggleButton/ToogleButton';


const FancyAssignAddOdds = ({ market_type, si,  onAddOdds,onRemoveOdds  }) => {
    
    const generateInitialOdds = (market_type) => {
        
        return [{
            selection_name: '',
            runNo: '',
            runYes: '',
            oddsNo: '',
            oddsYes: '',
            suspend: 0,
            si: si
        }];
        
    };
    const [newOdds, setNewOdds] = useState(generateInitialOdds(market_type)[0]);

    const handleInputChange = (field, value) => {
        if (value < 0) {
            return; 
        }
        setNewOdds(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const handleSubmit = () => {
        onAddOdds({
            ...newOdds
        });

        setNewOdds(generateInitialOdds(market_type)[0]); 
        onRemoveOdds()
    };
    return (
        <div>
            <>  
                <div>
                    <div  style={{ overflowX: "auto" }}>
                        <table width="100%">
                            <thead id='fancy-head-coloumn'>
                                <tr className=''>
                                    <th align='left'> SELECTION NAME </th>
                                    {market_type !== "khado_markets" && market_type !== "odd_even_markets" && <th>NO (OUTCOME)</th>}
                                    {market_type !== "khado_markets" && market_type !== "odd_even_markets" &&<th>YES (OUTCOME)</th>}
                                    {market_type !== "khado_markets" && market_type !== "odd_even_markets" && <th>NO (ODDS)</th>}
                                    {market_type !== "khado_markets" && market_type !== "odd_even_markets" && <th>YES (ODDS)</th>}
                                    <th> SUSPEND </th>
                                    <th> ACTION </th>
                                </tr>
                            </thead>
                            <tbody>   
                                <td>
                                    <input type="text" autoComplete="off" name="marketName" className='fancy-stake-input'  value={newOdds.selection_name}   onChange={(e) => handleInputChange('selection_name', e.target.value)} /> 
                                </td>
                                {market_type !== "khado_markets" && market_type !== "odd_even_markets" && (
                                        <>   
                                            <td>
                                                <input type="number"autoComplete="off" className="fancy-input" value={newOdds.runNo} onChange={(e) => handleInputChange('runNo', e.target.value)} />
                                            </td>
                                            <td>
                                                <input type="number" autoComplete="off" name="runYes" className="fancy-input" value={newOdds.runYes} onChange={(e) => handleInputChange('runYes', e.target.value)} />
                                            </td>
                                            <td>
                                                <input type="number" autoComplete="off" name="oddsNo" className="fancy-input" value={newOdds.oddsNo} onChange={(e) => handleInputChange('oddsNo', e.target.value)} />
                                            </td>
                                            <td>
                                                <input type="number" autoComplete="off" name="oddsYes" className="fancy-input" value={newOdds.oddsYes} onChange={(e) => handleInputChange('oddsYes', e.target.value)} />
                                            </td>
                                        </>
                                    )}
                                <td className="toggle-password">
                                    <ToogleButton  defaultChecked={newOdds.suspend===1}  value={newOdds.suspend}    onChange={(e) => handleInputChange('suspend', e.target.value)}/>
                                </td>
                                <td>
                                    <div className="toggle-password">
                                        <div className='actions_tab'>
                                            <div className='fancy-submit'>
                                                <MdPostAdd className="icon" onClick={handleSubmit}/>
                                            </div>
                                            
                                            <div className='fancy-cancel'>
                                                <MdDelete className="icon" onClick={onRemoveOdds}  />
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tbody>
                        </table>
                    </div> 
                </div>
            </>
        </div>
    );
};

export default FancyAssignAddOdds;



