import React, { useEffect, useState } from 'react';
import {Navigate, useNavigate, useLocation } from 'react-router-dom';
import Header from '../layout/header/Header';
import '../layout/layout.css';
import logo from '../assets/logo.png';
import { FaUser } from 'react-icons/fa';
import { FiAlertCircle, FiSettings  } from "react-icons/fi";
import AdminChangePassword from '../pages/user/AdminChangePassword';
import Modal from '../layout/sideBar/Modal';
import { getCaller, postCaller } from './api';
import AgentOddSettings from '../pages/downline/agentManageMarket/AgentOddSettings';

function ProtectedRoute({Children}) {
  const [event, setEvent] = useState([])
  const navigate = useNavigate();
  const location = useLocation();
  const [open,setOpen]= useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false);

  let userName = localStorage?.getItem('user_name')?.toLowerCase();
  let userType = localStorage?.getItem('user_role')?.toLowerCase();
  let token = localStorage?.getItem('token')?.toLowerCase();
  
  useEffect(() => {
    setOpen(false); 
    setSettingsOpen(false);
  }, [location]);
  
  const logoutHandle = async() => {
    await postCaller(`api/v1/auth/logout?token=${token}`);
    localStorage.removeItem('token');
    localStorage.removeItem('user_role' );
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_sus');
    navigate('/')
  }
  
  const isAuthenticated = localStorage.getItem('token');
  
  useEffect( () => {
    if (userType==='agent') {
      const getAssignMarket = async () => {
        const res = await getCaller(`api/v1/users/showAssignMarketAgent`);
        setEvent(res?.data);
      }
      getAssignMarket()
    }
  }, [userType]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    window.history.pushState(null, null, window.location.href);
  };
 
  const handleSettingsClose = () => {
    setSettingsOpen(false);
  };

  const handleSettingsOpen = () => {
    setSettingsOpen(true);
    window.history.pushState(null, null, window.location.href);
  };

  useEffect(() => {
    const handlePopState = () => {
      if (open || settingsOpen) {
        setOpen(false); 
        setSettingsOpen(false); 
        window.history.pushState(null, null, window.location.href);
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [open,settingsOpen]);

  const eventDate = event?.event_date ? event.event_date.substring(0, 10) : 'N/A';

  return (
      isAuthenticated ? 
        <>  
        {
          userType==='agent'?<>
            <div className={"header-right-top"}>
              <div className="header-agent-logo">
                <img src={logo} alt=""/>  
              </div>
              { event?.market_type === "bookmaker" ?
                <div className="span-book" style={{color:"white"}}>
                <p> BOOKMAKER MARKET </p>
                <span className="match-name">{event?.event_name}
                </span>
                <span className="match-name">  
                {eventDate}
                </span>
              </div> :  
              <div className="span-book" style={{color:"white"}}>
                <p> FANCY MARKET </p>
                <span className="match-name">
                  {event?.event_name}                
                </span>
                <span className="match-name">   
                {eventDate}
                </span>
              </div>
              }

              <div style={{color:"white"}}>
                <span  onClick={handleOpen} className='modal' style={{ color:"white", display:'inline-block' }}>
                  <FiAlertCircle />
                </span>
                { event?.market_type === "fancy" &&
                  <span onClick={handleSettingsOpen} className='modal' style={{ marginLeft: '10px', color:"white", display:'inline-block'}}>
                    <FiSettings />  
                  </span>   
                }               
              </div>
                
              <Modal isOpen={open} onClose={handleClose}>
                <>
                  <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Market Controls</h4>
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                      <tbody>
                          <tr>
                              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Suspend All Markets</td>
                              <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'right' }}>CTRL + Q</td>
                          </tr>
                          <tr>
                              <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Open All Markets</td>
                              <td style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'right' }}>CTRL + X</td>
                          </tr>
                      </tbody>
                  </table>
                </>
              </Modal>

              <div className='agentSettingMdl'>
                <Modal className="agentSettingMdl" isOpen={settingsOpen} onClose={handleSettingsClose}>
                  <AgentOddSettings onSaveClose={handleSettingsClose} />
                </Modal>
              </div>

              <div className="user-agent-1" onClick={logoutHandle}>
                <FaUser className='user-icon' />
                <p>  {userType==='admin'?null:<span className='userName-1' >{userName?.toUpperCase()}</span>} <span className='name'>({userType?.toUpperCase()})</span>  </p>
              </div>
            </div>
            <div> <Header /></div>
            <div className=''> {Children} </div>
          </> :
          <div className='side-container'>
            <div> <Header /></div>
            <div className={"header-right"}>
                <div className="top-nav">
                  {
                    userType==='admin'?<div className='admin-pass-btn'>
                      <button type='button' onClick={() => { setOpen(true); navigate('/admin_password_change')}}>Change Password</button>
                    </div>:null
                  }
                  <div className="refresh" onClick={logoutHandle}>
                    <FaUser className='user-icon' />
                    <div className='admin-password'>
                      <p className='userName-container'>  {userType==='admin'?null:<span className='userName'>{userName?.toUpperCase()}</span>} <span className='name'>({userType?.toUpperCase()} ) </span></p>
                    </div>
                  </div>
                </div>
                <div className='children-container'>
              {Children} 
              {
                open &&<div>
                  <AdminChangePassword setOpen={setOpen} open={open}/>
                </div>
              }
              </div>
            </div>
            
          </div>
        }
      </>
      : <Navigate to={'/'} replace/>
  )
}

export default ProtectedRoute