import '../eventManage/eventmanage.css';
import { motion } from "framer-motion";
import { MdDelete } from 'react-icons/md';
import { useNavigate } from "react-router-dom";
import { RiFootballFill } from 'react-icons/ri';
import React, { useEffect, useState } from 'react';
import Button from '../../component/button/Button';
import MarketSettingsTab from './MarketSettingsTab';
import ErrorBoundary from '../../error/ErrorBoundary';
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
import { getCaller, updateCaller, deleteCaller } from '../../services/api';
import { showToast, showConfirmationDialog, showDeleteMessage } from "../../utilities/toastHelper";

const MarketSettingList = () => {
    const navigate = useNavigate();
    const [eventTab, setEventTab] = useState('');
    const [getSportData, setGetSportData] = useState([]);
    const [marketSettingsList, setMarketSettingsList] = useState([]);
    const [openMarket, setOpenMarket] = useState([]);

    useEffect(() => {
        const getAllData = async () => {
            const res = await getCaller("api/v1/sports/management/getSport");
            if (res?.data) {

                let filteredSports = res.data.filter((sport) => ["cricket", "tennis", "soccer"].includes(sport.slug));
                filteredSports.push({ id: 0, name: "custom" });

                setGetSportData(filteredSports);

                if (filteredSports.length) {
                    setEventTab(filteredSports[0].id);
                }
            }
      };
      getAllData();
    }, []);

    useEffect(() => {
        if (eventTab || eventTab === 0) {
            const fetchMarketSettings = async () => {
                console.log(`Fetching market settings for eventTab: ${eventTab}`);
                const res = await getCaller(`api/v1/settings/allSettings/${eventTab}`);
                if (res?.data) {
                    setMarketSettingsList(res.data);
                }
            };
            fetchMarketSettings();
        }
    }, [eventTab]);    

    const handleInputChange = (marketId, marketType, field, value) => {
        if (value < 0) value = 0;
        setMarketSettingsList(prevSettings =>
            prevSettings.map(market => {
                if (market.id === marketId) {
                    const updatedLimits = JSON.parse(market.market_limits);
                    updatedLimits[marketType][field] = value;
                    return { ...market, market_limits: JSON.stringify(updatedLimits) };
                }
                return market;
            })
        );
    };

    const handleKeyDown = (e) => {
        const invalidKeys = ['+', '-', 'e', '`', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '=', '{', '}', '[', ']', '\\', '|', ':', ';', '"', "'", '<', '>', '?', '/', '~'];
        if (invalidKeys.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handleUpdateClick = async (marketId) => {
        const marketToUpdate = marketSettingsList.find(market => market.id === marketId);
        if (marketToUpdate) {
            const parsedMarketLimits = JSON.parse(marketToUpdate.market_limits);
            const dataToSend = {
                market_limits: parsedMarketLimits,
            };

            try {
                const updatedRes = await updateCaller(`api/v1/settings/updateSettings?id=${marketToUpdate.id}`, dataToSend);
                if (updatedRes.success) {
                    showToast("Market settings has been updated.", false);
                    navigate('/setting/market_settings');
                } else {
                    showToast("Failed to update market settings.", true);
                }
            } catch (error) {
                showToast("Failed to update market settings.", true);
            }
        }
    };

    const toggleMarket = (id) => {
        if (openMarket.includes(id)) {
            setOpenMarket(openMarket.filter(item => item !== id));
        } else {
            setOpenMarket([...openMarket, id]);
        }
    };

    const handleDelete = async (marketId) => {
        const marketToDelete = marketSettingsList.find(market => market.id === marketId);
        const confirmed = await showConfirmationDialog("You want to delete a market setting?");
        if (confirmed.isConfirmed) {
            try {
                const deleteResponse = await deleteCaller(`api/v1/settings/deleteSettings?id=${marketToDelete.id}`);
                if (deleteResponse.success === true) {
                    setMarketSettingsList(prevList => prevList.filter(market => market.id !== marketId));
                    showDeleteMessage("Your Market Setting Deleted Successfully.");
                }
            } catch (error) {
                showToast("Failed to delete market setting", true);
            }
        }
    };


    return (
        <>
            <div className="wrapper">
                <MarketSettingsTab />
                <div className="event-container spaceremove">
                    {getSportData.length ? getSportData.map(sport => (
                        <div
                            key={sport.id}
                            className={`event-tab ${eventTab === sport.id ? 'active-event-tab' : ''}`}
                            onClick={() => setEventTab(sport.id)}
                        >
                            <div className="event-body">
                                <div><RiFootballFill style={{ fontSize: "1.5rem" }} /><p>{sport.name}</p></div>
                            </div>
                        </div>
                    )) : <ErrorBoundary />}
                    <div className='agent-btn-container market-btn'>
                        <Button className='submit' type='button' name='Add Settings' onClick={() => navigate("/setting/AddMarketSetting")} />
                    </div>
                </div>

                <div>
                <motion.div className="league-setting-container"  initial={{ opacity: 0, x: '-100vh' }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 1 }}>
                    {marketSettingsList.length ? marketSettingsList.map((marketSettings) => (
                        <div key={marketSettings.id} className="market-section" style={{"border-bottom": "groove", "padding": "8px"}}>
                            <button className={`accordion ${openMarket.includes(marketSettings.id) ? 'active' : 'inactive'}`}
                                onClick={() => toggleMarket(marketSettings.id)} style={{'width': '100%', "text-wrap": "nowrap"}}>
                                {openMarket.includes(marketSettings.id) ? (
                                    <BsChevronDown style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                                ) : (
                                    <BsChevronRight style={{ fontSize: '1rem', fontWeight: 'bold' }} />
                                )}
                                Market Limits - {marketSettings.market_count}

                                <div className='edit-btn delete-btn' onClick={() => handleDelete(marketSettings.id)} style={{"color": "white", marginLeft: "auto"}}><MdDelete className="icon"/></div>
                            </button>

                            {openMarket.includes(marketSettings.id) && (
                                <div className="table-border tableRes">
                                    <table width="100%">
                                        <thead>
                                            <tr className='headingRes'>
                                                <th>Market</th>
                                                <th>Stake Limit</th>
                                                <th>Odd Limit</th>
                                                <th>Inplay Stake Limit</th>
                                                <th>Inplay Max Odd Limit</th>
                                                <th>Max Market Limit</th>
                                                <th>Inplay Max Market Limit</th>
                                                <th>Delay</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(JSON.parse(marketSettings.market_limits)).map((market, index) => (
                                                <tr key={index} className='custom-row'>
                                                    <td>{market}</td>
                                                    <td>
                                                        <input
                                                            autoComplete="off"
                                                            type="number"
                                                            placeholder="stake limit"
                                                            value={JSON.parse(marketSettings.market_limits)[market].stake_limit || ''}
                                                            className="league-detail-input"
                                                            onChange={(e) => handleInputChange(marketSettings.id, market, 'stake_limit', e.target.value)}
                                                            onKeyDown={handleKeyDown} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            autoComplete="off"
                                                            type="number"
                                                            placeholder="odd limit"
                                                            value={JSON.parse(marketSettings.market_limits)[market].odd_limit || ''}
                                                            className="league-detail-input"
                                                            onChange={(e) => handleInputChange(marketSettings.id, market, 'odd_limit', e.target.value)}
                                                            onKeyDown={handleKeyDown} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            autoComplete="off"
                                                            type="number"
                                                            placeholder="inplay stake limit"
                                                            value={JSON.parse(marketSettings.market_limits)[market].inplay_stake_limit || ''}
                                                            className="league-detail-input"
                                                            onChange={(e) => handleInputChange(marketSettings.id, market, 'inplay_stake_limit', e.target.value)}
                                                            onKeyDown={handleKeyDown} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            autoComplete="off"
                                                            type="number"
                                                            placeholder="inplay max odd limit"
                                                            value={JSON.parse(marketSettings.market_limits)[market].inplay_max_odd_limit || ''}
                                                            className="league-detail-input"
                                                            onChange={(e) => handleInputChange(marketSettings.id, market, 'inplay_max_odd_limit', e.target.value)}
                                                            onKeyDown={handleKeyDown} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            autoComplete="off"
                                                            type="number"
                                                            placeholder="max market limit"
                                                            value={JSON.parse(marketSettings.market_limits)[market].max_market_limit || ''}
                                                            className="league-detail-input"
                                                            onChange={(e) => handleInputChange(marketSettings.id, market, 'max_market_limit', e.target.value)}
                                                            onKeyDown={handleKeyDown} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            autoComplete="off"
                                                            type="number"
                                                            placeholder="inplay max market limit"
                                                            value={JSON.parse(marketSettings.market_limits)[market].inplay_max_market_limit || ''}
                                                            className="league-detail-input"
                                                            onChange={(e) => handleInputChange(marketSettings.id, market, 'inplay_max_market_limit', e.target.value)}
                                                            onKeyDown={handleKeyDown} 
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            autoComplete="off"
                                                            type="number"
                                                            placeholder="delay"
                                                            value={JSON.parse(marketSettings.market_limits)[market].delay || ''}
                                                            className="league-detail-input"
                                                            onChange={(e) => handleInputChange(marketSettings.id, market, 'delay', e.target.value)}
                                                            onKeyDown={handleKeyDown} 
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr className="custom-row">
                                                <td colSpan={7}>
                                                    <button className="update-league-btn" type="button" onClick={() => handleUpdateClick(marketSettings.id)}>Update</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    )) : <ErrorBoundary />}
                </motion.div>
                </div>
            </div>
        </>
    );
};

export default MarketSettingList;