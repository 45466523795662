const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
let date = "2022-11-04T00:00:00.000Z";
let interval;

export const handleMatchDate = () => {
    let finalDate = "";
    date = date.split('T')[0];
    finalDate = date.split('-');

    return `${finalDate[2]}, ${monthNames[finalDate[1]]} ${finalDate[0]}`
}
export const disablePastDate = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    const dd = String(sevenDaysAgo.getDate()).padStart(2, "0");
    const mm = String(sevenDaysAgo.getMonth() + 1).padStart(2, "0");
    const yyyy = sevenDaysAgo.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
}

export const disablePast3Date = () => {
    let today=new Date(new Date().getTime() - (3 * 24 * 60 * 60 * 1000));
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
}

export const callInInterval = (callback, params) => {
    clearTimeout(interval);
    return new Promise((resolve, reject) => {
        interval = setTimeout(async () => {
            try {
                const data = await callback(params);
                resolve(data);
            } catch (error) {
                reject(error);
            }
        }, 500);
    });
}

export  const selectStyles = {
    control: (provided, state) => ({
        ...provided,
        outline: "none",
        border: "1px solid #cbcbcb",
        height:'40px',
    }),
    menu: (provided, state) => ({
        ...provided,
        zIndex: 20,
    }),
    input: (provided, state) => ({
        ...provided,
        marginTop: "-7px"
    })
}