import React, { useEffect, useState, useRef } from 'react'
import { getCaller, postCaller } from '../../services/api';
import { motion } from "framer-motion";
import './result.css'
import MatchResultTable from './racingResultTable';
import Button from '../../component/button/Button';
import SurveillanceModel from '../../component/modal/SurveillanceModel';
import Select from 'react-select'
import moment from 'moment';
import Result from '../result/result';
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { selectStyles } from '../../services/utils'
import { showToast, showSuccessMessage, showConfirmationDialog} from '../../utilities/toastHelper';

const RacingResult = () => {
    const [sportList, setSportList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [venueList, setVenueList] = useState([])
    const [sport, setSport] = useState("")
    const [country, setCountry] = useState("")
    const [venue, setVenue] = useState()
    const [eventId, setEventId] = useState("")
    const [marketId, setMarketId] = useState("");
    const [resultId, setResultId] = useState("")
    const [marketList, setMarketList] = useState([])
    const [resultList, setResultList] = useState("")
    const [resultData, setResultData] = useState([])
    const [eventList, setEventList] = useState([])
    const [marketExternalId, setMarketExternalId] = useState("");
    const [isOpen, setOpen] = useState(false);
    const sportRef = useRef();
    const eventRef = useRef();
    const marketRef = useRef();
    const selectionRef = useRef();
    const countryRef = useRef();
    const venueRef = useRef();
    const [callRef, setCallRef] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [scope, setScope] = useState({})
    const [fetchedPages, setFetchedPages] = useState({});
    const perPage = 12
    useEffect(() => {
        const { result } = getLocalData('scope');
        const userType = getLocalData('user_role');
        setScope({result, userType})
        async function getSport() {
            let sportResponse = await getCaller('api/v1/racing/sports');
            setSportList(sportResponse.data)
        }
        getSport()
    }, [])

    useEffect(() => {
        const found = marketList?.find(m => m.marketId == marketId);
        if (found) {
            setMarketExternalId(found.market_id);
        } else {
            setMarketExternalId("");
        }
    }, [marketId, marketList])

    const handleSport = async (event) => {
        const sport = event.value
        setCountryList([])
        setVenueList([])
        setEventList([])
        setMarketList([])
        setResultList([])
        setCountry("")
        setVenue("")
        setEventId("")
        setMarketId("")
        setResultId("")
        if (sport) {
            setSport(sport)

            const Response = await getCaller(`api/v1/racing/country/all?sportId=${sport}`)
            setCountryList(Response.data)
        }
    }

    const handleCountry = async (event) => {
        const country = event.value
        setVenueList([])
        setEventList([])
        setMarketList([])
        setResultList([])
        setVenue("")
        setEventId("")
        setMarketId("")
        setResultId("")
        if (country) {
            setCountry(country)

            const Response = await getCaller(`api/v1/racing/venue/country/${sport}?countryCode=${country}`)
            setVenueList(Response.data)
        }
    }

    const handleVenue = async (event) => {
        const venue = event.value
        setEventList([])
        setMarketList([])
        setResultList([])
        setEventId("")
        setMarketId("")
        setResultId("")
        if (country) {
            setVenue(venue)

            const Response = await getCaller(`api/v1/racing/events/venue/${venue}`)
            setEventList(Response.data)
        }
    }

    const handleEvent = async (event) => {
        setMarketList([])
        setResultList([])
        setMarketId("")
        setResultId("")
        if (event) {
            setEventId(event.value)

            const marketFindResponse = await getCaller(`api/v1/racing/markets/event/${event.value}`)
            setMarketList(marketFindResponse.data)
        }
    }

    const handleMarket = async (event) => {
        const market = event.value
        setResultList([])
        setResultId("")
        setMarketId(market)
        const marketData = marketList.find(m => m.id === parseInt(market))

        setResultList(JSON.parse(marketData.runners))
    }

    const handleResult = (event) => {
        let selectResult = JSON.parse(event.value)
        setResultId(selectResult)
    }

    async function addResult() {
        const result = resultId;
        const marketData = marketList.find(m => m.id === parseInt(marketId))
        const data = {
            sport_id: parseInt(sport),
            match_id: eventId,
            market_id: marketId,
            result: resultId,
            market_name: marketData.market_name,
            selection: result.RN,
        };

        await showConfirmationDialog(`You want to Submit this Result `)
        .then(async (responseData) => {
            if (responseData.isConfirmed) {
                const response = await postCaller(`api/v1/results`, data)
                if (response.success === true) {
                    showSuccessMessage('Your Result is Submitted Successfully')
                    setResultList([])
                    setCountryList([])
                    setVenueList([])
                    setResultId('')
                    setMarketList([])
                    allResult()
                    setCallRef(true)
                } else {
                    showToast (response.message , true)

                }
            }
        })
    }
    useEffect(() => {
        allResult()
    }, [])

    const allResult = async (page = 1) => {
        if (fetchedPages[page]) {
            setResultData(fetchedPages[page]);
            if (page !==1) return;
          }
        const resultResponse = await getCaller(`api/v1/results/racing/all?page=${page}&perPage=${perPage}`);
        setResultData(resultResponse.data.result);
        setTotalPages(resultResponse.data.totalCount);
        setFetchedPages((prev) => ({ ...prev, [page]: resultResponse.data.result }));
    }
    useEffect(() => {
        if (callRef) {
            if (marketRef?.current?.Select || marketRef?.current) {
                marketRef?.current.setValue({ label: 'Select Market', value: null })
            }

            if (selectionRef?.current?.Select || selectionRef?.current) {
                selectionRef?.current.setValue({ label: 'Select Result', value: null })
            }
            setCallRef(false)
        }
    }, [callRef])
    
    return (
        <>  
            <Result />
            <motion.div className="wrapper" initial={{ opacity: 0, x: '-100vh' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}>
                <div className="result-sport-container">

                    <div className="result-sport-content-racing">
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Sport </label> <br />
                            <Select
                                onChange={(selectedOption) => handleSport(selectedOption)}
                                options={sportList.map((el) => ({ value: el.id, label: el.name }))}
                                placeholder="Select Sport"
                                ref={sportRef}
                                styles={selectStyles}
                            />
                        </div>
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Country </label> <br />
                            <Select
                                onChange={(selectedOption) => handleCountry(selectedOption)}
                                options={countryList.map((el) => ({ value: el.code, label: el.code }))}
                                placeholder="Select Country"
                                ref={countryRef}
                                styles={selectStyles}
                            />
                        </div>
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Venue </label> <br />
                            <Select
                                onChange={(selectedOption) => handleVenue(selectedOption)}
                                options={venueList.map((el) => ({ value: el.id, label: el.name }))}
                                placeholder="Select Venue"
                                ref={venueRef}
                                styles={selectStyles}
                            />
                        </div>
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Event </label> <br />
                            <Select
                                onChange={(selectedOption) => handleEvent(selectedOption)}
                                options={eventList.map((el) => ({ value: el.id, label: el.event_name }))}
                                placeholder="Select Event"
                                ref={eventRef}
                                styles={selectStyles}
                            />
                        </div>

                        <div className="result-event-select">
                            <label htmlFor="date"> Select Market </label> <br />
                            <Select
                                value={marketId ? { value: marketId, label: marketList.find(el => el.id === marketId)?.market_name } : null}
                                onChange={(selectedOption) => handleMarket(selectedOption)}
                                options={marketList.map((el) => ({ value: el.id, label: `${el.market_name} (${moment.utc(el.market_time).local().format('DD-MM-YYYY HH:mm:ss')})` }))}
                                placeholder="Select Market"
                                ref={marketRef}
                                styles={selectStyles}
                            />
                        </div>
                        <div className="result-event-select">
                            <label htmlFor="date"> Select Result </label> <br />

                            <Select
                                value={resultId ? { value: resultId, label: resultList.find(el => el.SID === resultId.SID)?.RN } : null}
                                onChange={(selectedOption) => handleResult(selectedOption)}

                                options={(resultList?.length > 0) ? [
                                    { value: '', label: 'Select Result' },
                                    ...resultList?.map((el) => ({ value: JSON.stringify(el), label: el.RN })),
                                    { value: JSON.stringify({ RN: 'tie', SID: 'tie' }, 1), label: 'Tie' },
                                    { value: JSON.stringify({ RN: 'abandoned', SID: 'abandoned' }, 1), label: 'Abandoned' },
                                ] : []}
                                placeholder="Select Result"
                                styles={ selectStyles }
                            />
                        </div>
                    </div>
                    <div className="book-maker-btn">
                        <Button className='submit' type='button' name='Submit Result' disabled={!(resultId !== "" && (scope?.userType !== 'manager' || scope?.result?.add))} onClick={() => { setOpen(true) }} />
                    </div>
                </div>
                <MatchResultTable resultData={resultData} setResultData={setResultData} page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    perPage={perPage}
                    allResult={allResult}
                />
            </motion.div>
            <SurveillanceModel isOpen={isOpen} setOpen={setOpen} onCloseModel={addResult} matchID={eventId} marketID={marketExternalId} marketType={1}></SurveillanceModel>
        </>
    )
}

export default RacingResult