import React from 'react'
import { MdSave } from 'react-icons/md'
import ToogleButton from '../../component/toggleButton/ToogleButton'
import '../manageOutsideMarket/manageskymarket.css'
import { updateCaller } from '../../services/api';
import { showToast } from '../../utilities/toastHelper';
const ManageBookmakerTable = ({ getBookmakerList, setGetBookmakerList }) => {

  const handleChange = (index, fieldName, value) => {
    let newFormValues = [...getBookmakerList.data];
    newFormValues[index][fieldName] = value;
    newFormValues[index] = { ...newFormValues[index] };
    setGetBookmakerList({ data: newFormValues })
  }

  const handleToggle = (fieldName, value, index, eventID, bookmakerID) => {
    const updatedBookmakers = [...getBookmakerList.data];
    updatedBookmakers[index][fieldName] = value ? 1 : 0;
    updatedBookmakers[index] = { ...updatedBookmakers[index] };
    setGetBookmakerList({ data: updatedBookmakers });
  }

  const handleUpdateBookmaker = async (eventID, bookmakerID, index) => {
    const bookmaker = getBookmakerList.data[index]
    const response = await updateCaller(`api/v1/bookmakers/${eventID}/${bookmakerID}`, bookmaker)
    if (response) {
      showToast(response.message, false)
    }
  }
 
  return (
    <div className="sky-fancy-market" style={{ overflowX: "auto" }}>
      <div>
        <div className="sky-fancy-head">
          <p>BOOKMAKER MARKET</p>
        </div>
        <table width="100%" className='sky-fancy-table'>
          <thead>
            <tr id='fancy-head-coloumn'>
              <th> TITLE </th>
              <th> MAX STAKE </th>
              <th> MAX MARKET LIMIT </th>
              <th> BET DELAY </th>
              <th> SUSPEND </th>
              <th> COMMISSION</th>
              <th> ACTIVE </th>
              <th> ACTIONS </th>
            </tr>
          </thead>
          <tbody>
            {getBookmakerList.data.length > 0 ? (
              getBookmakerList.data.map((bookmaker, index) => (
                <tr className="sky-fancy-row" key={bookmaker.id}>
                  <td>
                    <input
                      type="text"
                      name="market_name"
                      className="fancy-stake-input-1"
                      value={bookmaker.market_name||""}
                      onChange={(event) =>
                        handleChange(index, "market_name", event.target.value)
                      }
                      placeholder="Bookmaker Name"
                      style={{ width: "inherit" }}
                    /> <br />
                    <input
                      type="text"
                      name="msg"
                      className="fancy-stake-input-1"
                      value={bookmaker.msg||""}
                      onChange={(event) =>
                        handleChange(index, "msg", event.target.value)
                      }
                      placeholder="Message"
                      style={{ width: "inherit" }}
                    />
                  </td>
                  <td>
                    <input
                      type="number" autoComplete="off"
                      className="sky-bookmaker"
                      placeholder="Stake limit"
                      value={bookmaker.stake_limit||""}
                      onChange={(event) =>
                        handleChange(index, "stake_limit", event.target.value)
                      }
                      name="stakelimit"
                      style={{ width: "inherit" }}
                    />
                  </td>
                  <td >
                    <input type="number" autoComplete="off"
                      placeholder='Max Stake limit'
                      name="yes_outcome" className='sky-bookmaker'
                      onChange={(event) => handleChange(index, "max_market_limit", event.target.value)}
                      value={bookmaker.max_market_limit||""}
                      style={{ width: "inherit" }}
                    />
                  </td>
                  <td >
                    <input type="number" autoComplete="off"
                      placeholder='Bet Delay'
                      name="no_outcome" className='sky-bookmaker'
                      onChange={(event) => handleChange(index, "bet_delay", event.target.value)}
                      value={bookmaker.bet_delay||""}
                      style={{ width: "inherit" }}
                    />
                  </td>
                  <td>
                    <div className="toggle-password">
                      <ToogleButton
                        onChange={(value) => handleToggle("is_sus", value, index, bookmaker.eventID, bookmaker.bookmakerID)}
                        defaultChecked={bookmaker.is_sus ? true : false}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="toggle-password">
                      <ToogleButton
                        onChange={(value) => handleToggle("is_com", value, index, bookmaker.eventID, bookmaker.bookmakerID)}
                        defaultChecked={bookmaker.is_com ? true : false}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="toggle-password">
                      <ToogleButton
                        onChange={(value) => handleToggle("is_active", value, index, bookmaker.eventID, bookmaker.bookmakerID)}
                        defaultChecked={bookmaker.is_active ? true : false}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="toggle-password">
                      <div className="actions_tab">
                        <div className="fancy-submit">
                          <MdSave
                            className="icon"
                            onClick={() =>
                              handleUpdateBookmaker(
                                bookmaker.eventID,
                                bookmaker.bookmakerID,
                                index
                              )
                            }
                            tabIndex={0} onKeyDown={(e) => {
                              if (e.keyCode === 13 || e.key === " " || e.code === "Space" || e.keyCode === 32) {
                                e.preventDefault(); handleUpdateBookmaker(
                                  bookmaker.eventID,
                                  bookmaker.bookmakerID,
                                  index
                                )
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))) : (
              <tr className=''>
                <td className='no-data-coloumn' align='center' colSpan={10}>
                  No Data
                </td>
              </tr>
            )
            }
          </tbody>

        </table>
      </div>
    </div>
  )
}
export default ManageBookmakerTable