import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../component/button/Button'
import { getCaller, updateCaller, postCaller } from '../../../services/api';
import FancyAssignMarket from './FancyAssignMarket';
import BookmakerAssignMarket from './BookmakerAssignMarket';
import MainMarketResultMaker from './MainMarketResultMaker';
import '../downline.css'
import { motion } from "framer-motion";
import { disablePastDate } from '../../../services/utils';
import getLocalData from '../../../utilities/LocalStorageData.utilities';
import { showConfirmationDialog, showToast } from '../../../utilities/toastHelper';

const ShowAssignMarket = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const userName = state?.name
    const [fancyAssignData, setFancyAssignData] = useState([])
    const [marketOpen, setMarketOpen] = useState(false);
    const [sport, setSport] = useState("")
    const [event, setEvent] = useState({})
    const [scope,setScope] = useState()

    const [createMarket, setCreateMarket] = useState({
        event_date: new Date().toISOString().split('T')[0],
        sus_time: 0
    })

    const handleChange = (event) => {
        const { name, value } = event.target
        if(name === 'sus_time'){
            value.replace(/[^0-9]/g, '');
            setCreateMarket({ ...createMarket, [name]: (value) })
        } else {
            setCreateMarket({ ...createMarket, [name]: value });
        }
    }

    const handleSport = async (event) => {
        const sport = event.target.value
        setSport(sport)
        if (sport === "") {
            return;
        }
    }

    async function getMarket() {
        if (userName?.market_type === 'main_market') {
            const res = await getCaller(`api/v1/events/${userName.match_id}`);
            setEvent(res.data);
        }
        setMarketOpen(true);
    }

    useEffect(() => {
        const {markets} = getLocalData('scope')
        setScope(markets)
        getMarket()
    }, [])

    const renderOption = () => {
        if (userName.market_type === 'bookmaker') {
            return null;
        } else {
            return (
                <option value={userName.market_id}>{userName.market_id}</option>
            );
        }
    }; 
    
    async function remove() {
        try {
            await showConfirmationDialog(`You are about to delete a market.`)
            .then(async (result) => {
                if (result.isConfirmed) {
                    const res = await updateCaller(`api/v1/users/${userName.id}/release`);
                    if (res.success === true) {
                        showToast( "Market removed successfully!",false)
                        navigate('/downline');
                    } else {
                        showToast( res.message , true)
                    }
                }
            })
    } catch (error) {
        console.error(error);
    }
}

return (
    <>
        {
            <motion.div className="wrapper" initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01]
                }}>

                <div className="wrapper-head">
                    <h3>ASSIGN MARKET OF <span style={{ color: "#018990" }}>{userName.user_id} </span> </h3>
                    <div className='offline' > <Button className='submit' name='Offline' type='button' /> </div>
                </div>


                <div className="result-sport-container" >
                    <div className="result-sport-content">
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Date </label> <br />
                            <input type='date' autoComplete="off" disabled={true} min={disablePastDate()} value={createMarket.event_date} onChange={(e) => handleChange(e)}
                                name='event_date' className='select-field' placeholder='DD/MM/YYYY' />
                        </div>
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Sport </label> <br />
                            <div className='select-field manager_downline' style={{ fontSize: 'small' }} onChange={async (event) => { handleSport(event, sport) }}>
                                <option  > {userName?.sport_name} </option>

                            </div>
                        </div>
                        <div className="result-event-select">
                            <label htmlFor="date">Select Event</label>
                            <br />
                            <div className='select-field manager_downline' style={{ fontSize: 'small' }} name='event_name' onChange={handleChange}  >
                                <option value=''> {userName.event_name}</option>                             
                            </div>
                        </div>
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Market Type </label> <br />
                            <div className='select-field manager_downline' style={{ fontSize: 'small' }} name='event_name' onChange={handleChange}  >
                                <option value=""> {userName.market_type} </option>    
                            </div>
                        </div>
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Select Market </label> <br />
                            <div className='select-field manager_downline' style={{ fontSize: 'small' }} name='event_name' onChange={handleChange} disabled={userName.market_type === 'bookmaker'} >
                                {/* <option value="" > {userName.market_id} </option> */}
                                {renderOption()}
                            </div>
                        </div>
                        <div className="result-fancy-select">
                            <label htmlFor="date"> Suspension </label> <br />
                            <input type="text"  inputMode="numeric" autoComplete="off" disabled={true} name="sus_time" onChange={handleChange} value={userName.sus_time} className='select-field' id="" />
                        </div>
                    </div>
                    <br />
                    <div className="result-sport-content">
                        <div className="result-fancy-select">
                            <Button className='submit' disabled={!scope?.delete} name='Remove' type='button'  onClick={remove} />
                        </div>
                    </div>
                </div>
                <div>
                    {
                        userName?.market_type === 'main_market' && marketOpen ?
                            <MainMarketResultMaker userName={userName} uid={userName.id} sport={userName.sport_id} event={event} susTime={userName?.sus_time} />
                            : null
                    }
                    {
                        userName?.market_type === 'bookmaker' && marketOpen ?
                            <BookmakerAssignMarket userName={userName} />
                            : null

                    }
                    {
                        userName?.market_type === 'fancy' && marketOpen ?
                            <FancyAssignMarket userName={userName} setMarketOpen={setMarketOpen} fancyAssignData={fancyAssignData} setFancyAssignData={setFancyAssignData} />
                            : null
                    }

                </div>
            </motion.div>
        }
    </>
)
}

export default ShowAssignMarket