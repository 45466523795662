import * as Yup from 'yup';
import Select from 'react-select';
import '../customGames/custom.css';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { HiMinusCircle } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';
import { BsPlusCircleFill } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';
import Button from '../../component/button/Button';
import 'react-datepicker/dist/react-datepicker.css';
import { selectStyles } from '../../services/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCaller, postCaller, updateCaller } from '../../services/api';
import { showConfirmationDialog, showSuccessMessage, showToast } from '../../utilities/toastHelper';

const CustomAddMatch = () => {
    const [sportList, setSportList] = useState([]);
    const [dateError, setDateError] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [leagueList, setLeagueList] = useState([]);

    const { state } = useLocation();
    const navigate = useNavigate();

    const updateMatchData = state?.matchData ?? {};
    const isAddMode = !updateMatchData.id;
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setDateError("");
    };

    useEffect(() => {
        async function getSport() {
            let sportResponse = await getCaller('api/v1/sports/management/getSport');
            setSportList(sportResponse?.data);
        }
        if (isAddMode) getSport();
    }, [isAddMode]);

    useEffect(() => {
        if (!isAddMode && updateMatchData) {
            formik.setFieldValue('event_name', updateMatchData.event_name || "", false);
            
            setSelectedDate(updateMatchData.event_date ? new Date(updateMatchData.event_date) : null);
    
            const runners = Array.isArray(updateMatchData.selection_runners)
                ? updateMatchData.selection_runners.map(runner => ({ selection_runners: runner.RN }))
                : JSON.parse(updateMatchData.selection_runners || '[]').map(runner => ({ selection_runners: runner.RN }));
    
            formik.setFieldValue('runners', runners, false);
        }
    }, [isAddMode, updateMatchData]);

    const validationMatch = Yup.object().shape({
        event_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Match Name is Required'),
        ...isAddMode ? { event_id: Yup.number().min(-999999999999999, 'Too Short!').max(999999999999999, 'Too Long!').required('Match Id is Required.') } : {},
        ...isAddMode ? { sport_id: Yup.number().required('Sport is Required.') } : {},
        ...isAddMode ? { league_id: Yup.number().required('League is Required.') } : {},
        runners: Yup.array().of(
            Yup.object().shape({
                selection_runners: Yup.string().matches(/^[A-Za-z0-9\s.,]+$/, 'Only alphabets and numbers are allowed')
                    .min(2, 'Too Short!').max(50, 'Too Long!').required('Runner Name is Required'),
            })
        ).min(1).required('Runner is Required'),
    });

    const formik = useFormik({
        initialValues: {
            ...isAddMode ? { sport_id: "" } : {},
            event_name: updateMatchData.event_name || "",
            ...isAddMode ? { event_id: Date.now() } : {},
            ...isAddMode ? { event_date: "" } : {},
            runners: Array.isArray(updateMatchData.selection_runners) ? updateMatchData.selection_runners.map(runner => ({
                selection_runners: runner.RN
            })) : [{ selection_runners: "" }],
            ...isAddMode ? { league_id: "" } : {},
        },
        validationSchema: validationMatch,

        onSubmit: async (values, { resetForm }) => {
            values.runners = values.runners.map((e) => e.selection_runners.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '));
            values.event_name = values.event_name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            if (!selectedDate) {
                setDateError("Date not Provided");
                return;
            }
        
            setDateError("");
            const formattedDate = moment(new Date(selectedDate).toISOString()).utc().format("YYYY-MM-DD HH:mm:ss");
            values.event_date = formattedDate;
        
            const actionText = isAddMode ? "add" : "update";
            const confirmationText = `You want to ${actionText} this match (${values.event_name})?`;
            const result = await showConfirmationDialog(confirmationText);
        
            if (result.isConfirmed) {
                const apiCaller = isAddMode ? postCaller : updateCaller;
                const endpoint = isAddMode ? "api/v1/events/addMatch" : `api/v1/events/updateMatch?id=${updateMatchData.id}`;
                const response = await apiCaller(endpoint, values);
        
                if (response.success === true) {
                    const successMessage = `Your match was ${isAddMode ? "added" : "updated"} successfully.`;
                    showSuccessMessage(successMessage);
                    navigate("/custom_games/custom_matches");
                } else {
                    showToast(response.message, true);
                }
            }
        }
    });

    const addInputField = () => {
        formik.setFieldValue('runners', [...formik.values.runners, { selection_runners: "" }], false);
    };

    const removeInputFields = (i) => {
        formik.values.runners.splice(i, 1);
        formik.setFieldValue('runners', [...formik.values.runners]);
    };

    useEffect(() => {
        const handleSport = async (sport) => {
            if (sport === "") {
                setLeagueList([]);
                return;
            }
            if (isAddMode) {
                try {
                    let leagueResponse = await getCaller(`api/v1/leagues/leaguesFindById?id=${sport}`);
                    setLeagueList(leagueResponse.data);
                } catch (err) {
                    console.error(err);
                }
            }
        };
        handleSport(formik.values.sport_id).then().catch(err => console.error(err));
    }, [formik.values.sport_id, isAddMode]);

    return (
        <div className="sport-container">
            <div className="agent-modal">
                <div className='add-agent-head'>
                    <div> {isAddMode ? "Add Match" : "Update Match"} </div>
                    <div className="ai-close">
                        <AiOutlineClose onClick={() => navigate('/custom_games/custom_matches')}/>
                    </div>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <div className="add-sport-content">
                        {
                            isAddMode ? (
                                <>
                                    <div className="add-sport-input" style={{ marginTop: '-7px' }}>
                                        <label htmlFor="sport"> Sport <span style={{ color: 'red' }}>*</span> </label> <br />
                                        <Select name="sport_id" onChange={(selectedOption) => formik.setFieldValue('sport_id', selectedOption?.value)}
                                            options={sportList.map((el) => ({ value: el.id, label: el.name }))} placeholder="Select Sport" styles={selectStyles}
                                        />
                                        {formik.errors.sport_id && formik.touched.sport_id && <span className="error" style={{ color: "red" }}>{formik.errors.sport_id}</span>}
                                    </div>
                                    
                                    <div className="add-sport-input" style={{ marginTop: '15px' }}>
                                        <label htmlFor="sport"> League <span style={{ color: 'red' }}>*</span> </label> <br />
                                        <Select onChange={(selectedOption) => formik.setFieldValue('league_id', selectedOption?.value)}
                                            options={leagueList.map((el) => ({ value: el.id, label: el.name }))} placeholder="Select League" styles={selectStyles}
                                        />
                                        {formik.errors.league_id && formik.touched.league_id && <span className="error" style={{ color: "red" }}>{formik.errors.league_id}</span>}
                                    </div>

                                    <div className="add-sport-input" style={{ marginTop: '15px' }}>
                                        <label htmlFor="sport"> Match Id <span style={{ color: 'red' }}>*</span> </label> <br />
                                        <input type="text" autoComplete="off" onBlur={formik.handleBlur} name="event_id" value={formik.values.event_id}  placeholder='Match Id' onChange={formik.handleChange} />
                                        {formik.errors.event_id && formik.touched.event_id && <span className="error" style={{ color: "red" }}>{formik.errors.event_id}</span>}
                                    </div>
                                </>
                            ) : null
                        }
                        
                        <div className="add-sport-input" style={{ marginTop: isAddMode ? '15px' : '-7px' }}>
                            <label htmlFor="sport"> Match Name <span style={{ color: 'red' }}>*</span> </label> <br />
                            <input type="text" autoComplete="off" onBlur={formik.handleBlur} name="event_name" value={formik.values.event_name} placeholder='Match Name' onChange={formik.handleChange} />
                            {formik.errors.event_name && formik.touched.event_name && <span className="error" style={{ color: "red" }}>{formik.errors.event_name}</span>}
                        </div>
            
                        <div className="add-sport-input" style={{ marginTop: '15px' }}>
                            <label htmlFor="sport"> Event Date and Time <span style={{ color: 'red' }}>*</span> </label> <br />
                            <div className="date-picker-wrapper">
                                <DatePicker name="event_date" selected={selectedDate} onChange={handleDateChange} showTimeSelect timeFormat="HH:mm" placeholderText='Date & Time' timeIntervals={15}
                                    clearButtonClassName='s' dateFormat="MMMM d, yyyy h:mm aa" onKeyDown={(e) => { e.preventDefault() }}
                                />
                                {dateError && <span className="error" style={{ color: "red" }}>{dateError}</span>}
                            </div>
                        </div>

                        {
                            formik.values.runners.map((data, index) => {
                                return (
                                    <div className="add-sport-input" style={{ marginTop: '15px' }} key={index}>
                                        <label htmlFor="sport"> Runners <span style={{ color: 'red' }}>*</span> </label> <br />
                                        <input type="text" autoComplete="off" name={`runners.${index}.selection_runners`} onChange={ formik.handleChange } id="sport" value={formik.values.runners[index].selection_runners} placeholder='Runners' />
                                        {
                                            formik.errors.runners?.[index]?.selection_runners && formik.touched.runners?.[index]?.selection_runners && (
                                            <span className="error" style={{ color: "red" }}>{formik.errors.runners[index].selection_runners}</span>)
                                        }
                                    </div>
                                )
                            })
                        }
                    
                        <div className="" style={{ marginTop: '15px' }}>
                            {
                                formik.values.runners.length > 1 && (<button type='button' className='add-field' onClick={removeInputFields}><HiMinusCircle />Remove Field</button>)
                            }
                            <button type='button' className='add-field' style={{ marginTop: '10px'}} onClick={addInputField}><BsPlusCircleFill />Add Field</button>
                        </div>
                    </div>

                    <div className="sport-btn-container">
                        <div>
                            <Button type='submit' className='submit' name="Submit" disabled={!(formik.isValid && formik.dirty) || formik.values.runners.some((runner) => !runner.selection_runners?.trim())} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default CustomAddMatch;