import React, {  useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { IoSearchOutline, } from 'react-icons/io5';
import Pagination from '../../component/pagination/Pagination'
import Button from '../../component/button/Button'
import '../result/result.css'
import moment from 'moment';
import { updateCaller, postCaller } from '../../services/api';
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import getLocalData from '../../utilities/LocalStorageData.utilities';
import { showToast, showSuccessMessage, showConfirmationDialog} from '../../utilities/toastHelper';

const FancyResultTable = ({ resultData, setResultData, setFancyId, allFancyResult, pagination }) => {
  const [inputValue, setInputValue] = useState("")
  let userType = localStorage?.getItem('user_role')?.toLowerCase();
  const [rowOpen, setRowOpen] = useState(null);
  const [showResultStatus, setShowResultStatus] = useState(false);
  const [showRollbackStatus, setShowRollbackStatus] = useState(false);
  const [disabledButtons, setDisabledButtons] = useState([]);
  const [scope,setScope] = useState({})

  useEffect(()=>{
    const {rollback, result} = getLocalData('scope')
    const userType = getLocalData('user_role')
    setScope({rollback, userType, result})
  },[])

  const [currentPage, setCurrentPage] = useState(pagination?.currentPage || 1);
    const handlePageChange = async(page) => {
    setCurrentPage(page);
    await allFancyResult(page)
  };

  // const handleFancyResultType = async (e) => {
  //   setInputValue(e)
  //   callInInterval(updateData, e)

  // }
  // const updateData = async ( data ) => {
  //   const resultResponse = await getCaller(`api/v1/results/fancy/${data}`)
  //   setResultData(resultResponse.data)
  // }
 
  let completeData = resultData?.filter((el) => {
    return ( 
      el?.event_name?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1 ||
      el?.sport_name?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1 ||
      el?.id?.toString().toLowerCase().indexOf(inputValue?.toString()) !== -1 ||
      el?.market_name?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1 ||
      el?.market_type?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1
    )
  });

  const handleRollBack = async (resultID) => {
    await showConfirmationDialog('You want to Rollback the Result?')
    .then(async (result) => {
      if (result.isConfirmed) {
        const response = await updateCaller(`api/v1/results/${resultID}/rollback`);

        if (response.success === true) {
          showSuccessMessage('The result was rollbacked successfully!')
          setResultData(resultData.map(e => e.id !== resultID ? e : { ...e, is_rollback: 1 }))
        } else {
          showToast (response.message , true)
        }
        setResultData(resultData.map(e => e.id !== resultID ? e : { ...e, is_rollback: 1 }))
      }
    })
  };

  const declareResult = async (id, operator) => {
    let data = {
      id: id,
      operator: operator
    };

    const formatOperator = operator.replace(/([A-Z]+)([A-Z][a-z]+)/, '$1 $2').toLowerCase().replace(/(^\w|\s\w)/g, char => char.toUpperCase());

    await showConfirmationDialog(`You want to Declare Result for ${formatOperator} market?`)
    .then(async (result) => {
      if (result.isConfirmed) {
        const res = await postCaller(`api/v1/results/declareResult`, data);
        if (res) {
          showSuccessMessage('The result was declared successfully!')
          setDisabledButtons((prevDisabled) => [...prevDisabled, operator]);
        } else {
          showToast ('Failed to declare the result. Please try again.' , true)
        }
      }
    });
  };

  const toggleRow = (index) => {
    if (rowOpen === index) {
      setRowOpen(null);
    } else {
      setRowOpen(index);
      setShowRollbackStatus(false);
      setShowResultStatus(false);
    }
  };

  const toggleResultStatus = () => {
    setShowResultStatus(!showResultStatus);
    setShowRollbackStatus(false);
  };

  const toggleRollbackStatus = () => {
    setShowRollbackStatus(!showRollbackStatus);
    setShowResultStatus(false);
  };

  const filteredManagers = completeData?.filter((market) =>{
    const marketName = market.market_name ? market.market_name.toLowerCase() : "";
    const eventName = market.event_name ? market.event_name.toLowerCase() : "";
    const marketType = market.market_type ? market.market_type.toLowerCase() : "";
    return marketName.includes(inputValue.toLowerCase()) || eventName.includes(inputValue.toLowerCase()) || marketType.includes(inputValue.toLowerCase());
  });

  return (
    <>
      <div className="table-border">
        <div className="wrapper-body">
          <div className="wrapper-content-1">
            <div className='searchcard'>
              <input type="text" autoComplete="off" name="" id="" placeholder='Search' className='result-input'
                onChange={(event) => setInputValue( event.target.value ) }
                value={inputValue}
              />
              {inputValue ? <div className="ai-close">
                <AiOutlineClose onClick={(event) => setInputValue('')}
                />
              </div> : <div className='ai-close'> <IoSearchOutline /></div>
              }
            </div>
          </div>
        </div>
        <div style={{ overflowX: "auto" }} >
          <table width="100%"  >
            <thead>
              <tr className='custom-row' >
                <th style={{ textAlign:"center"}}> # </th>
               
                <th width="140"> Declared By </th>
                <th> SPORT </th>
                <th> EVENT NAME </th>
                <th width="120"> MARKET Type </th>
                <th width="140"> MARKET NAME</th>
                <th width="140"> RESULT </th>
                <th> DATE </th>
                {
                  (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) &&  <th> <div> ROLLBACK </div> </th>
                }
              </tr>
            </thead>

            <tbody>
              {
                filteredManagers?.length > 0 ?
                filteredManagers?.map((e, i) => (
                    <React.Fragment key={i}>
                      <tr className='custom-row' key={i} onClick={() => toggleRow(i)}>
                        <td><div className='result_status'>
                          {rowOpen === i ? (
                            <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginRight: "8px" }} />
                          ) : (
                            <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginRight: "8px" }} />
                          )}
                          {e.id}
                        </div> </td>
                       
                        <td> {e.created_by ? e.created_by : "No Data"} </td>
                        <td style={{ textTransform: "capitalize" }}> {e.sport_name ? e.sport_name : "No Data"} </td>
                        <td> {e.event_name ? e.event_name : "No Data"} </td>
                        <td style={{ textTransform: "capitalize" }}> {e.market_type ? e.market_type : "No Data"} </td>
                        <td> {e.market_name} </td>
                        <td> {e.result ? e.result : "No Data"} </td>
                        <td> {(new Date(e.created_at)).toLocaleString()} </td>
                        {
                          (scope?.userType === "admin" || (scope?.userType === 'manager' && scope?.rollback.add)) && <td>
                            <div> <Button className = 'submit' type = 'button' name = 'RollBack' onClick = {() => handleRollBack(e.id)} disabled = {(scope?.userType === 'manager' && scope?.rollback.add) || e.is_rollback} /> </div>
                          </td>
                        }
                      </tr>
                      {rowOpen === i && (
                        <>
                          <tr className='custom-row' onClick={toggleResultStatus}>
                            <td colSpan={9}>

                              <div className='result_status'>&nbsp; &nbsp;  
                                {showResultStatus ? (
                                  <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                ) : (
                                  <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                )}
                                Result Status
                              </div>
                            </td>
                          </tr>
                          {e.result_status && showResultStatus && (
                            <tr className='custom-row'>
                              <td colSpan={9}>
                                <div className='result_status_table'>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Market</th>
                                        <th>Request Time</th>
                                        <th>Status</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Process Count</th>
                                        <th>User Count</th>
                                        <th>Bet Count</th>
                                        <th>End Point</th>
                                        <th width='220'>Declare Result</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.entries(JSON.parse(e.result_status)).map(([market, data], index) => (
                                        <tr key={index}>
                                          <td>{market}</td>
                                          <td>{(data?.requestTime) ? moment.unix(data.requestTime).format('YYYY-MM-DD HH:mm'): '-'} </td>
                                          <td>{data.status}</td>
                                          <td>{(data.startTime === '-') ? data.startTime : moment.unix(data.startTime).format('YYYY-MM-DD HH:mm')}</td>
                                          <td>{(data.endTime === '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td> 
                                          <td>{data.processCount}</td>
                                          <td>{data.userCount}</td>
                                          <td>{data.betCount}</td>
                                          <td>{data.endpoint}</td>
                                          <td className='custom-row'>
                                            <button className='basic-button submit' style={{textWrap:"nowrap", width:"200px"}}
                                              disabled={(scope?.userType==='manager' && !scope?.result?.add) || disabledButtons.includes(market) || (e.operators_list ? JSON.parse(e.operators_list).includes(market) : false) || e.is_rollback} 
                                              onClick={() => declareResult(e.id, market)}>Declare Result
                                            </button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          )}

                          { e.is_rollback ? (
                          <tr className='custom-row' onClick={toggleRollbackStatus}>
                            <td colSpan={9}>
                              <div className='result_status'>&nbsp; &nbsp;  
                                {showRollbackStatus ? (
                                  <BsChevronDown style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                ) : (
                                  <BsChevronRight style={{ fontSize: "1rem", fontWeight: "bold", marginBottom: "-3px" }} />
                                )}
                                Rollback Status

                              </div>
                            </td>
                          </tr>
                          ) : "" }
                          {e.rollback_status && showRollbackStatus && (
                            <tr className='custom-row'>
                              <td colSpan={9}>
                                <div className='result_status_table'>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Market</th>
                                        <th>Request Time</th>
                                        <th>Status</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Process Count</th>
                                        <th>User Count</th>
                                        <th>Bet Count</th>
                                        <th>End Point</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.entries(JSON.parse(e.rollback_status)).map(([market, data], index) => (
                                        <tr key={index}>
                                          <td>{market}</td>
                                          <td>{(data?.requestTime) ? moment.unix(data.requestTime).format('YYYY-MM-DD HH:mm'): '-'} </td>
                                          <td>{data.status}</td>
                                          <td>{(data.startTime === '-') ? data.startTime : moment.unix(data.startTime).format('YYYY-MM-DD HH:mm')}</td>
                                          <td>{(data.endTime === '-') ? data.endTime : moment.unix(data.endTime).format('YYYY-MM-DD HH:mm')}</td> 
                                          <td>{data.processCount}</td>
                                          <td>{data.userCount}</td>
                                          <td>{data.betCount}</td>
                                          <td>{data.endpoint}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </React.Fragment>

                  )) : <tr className=''><td className='no-data-coloumn' align='center' colSpan={10}>No Data</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
        <Pagination
          currentPage={currentPage}
          totalPages={pagination?.totalPages || 0}
          onPageChange={handlePageChange}
        />
    </>
  )
}

export default FancyResultTable