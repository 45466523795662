import React, { useState, useEffect } from 'react'
import ToogleButton from '../../../component/toggleButton/ToogleButton'
import '../downline.css'
import { getCaller, updateCaller, postCaller } from '../../../services/api';
import Button from '../../../component/button/Button';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { selectStyles } from '../../../services/utils';
import getLocalData from '../../../utilities/LocalStorageData.utilities';
import { showConfirmationDialog, showSuccessMessage, showToast } from '../../../utilities/toastHelper';

const BookmakerAssignMarket = ({ userName }) => {
  const [allowAddBookmaker] = useState(true);
  const [runners, setRunners] = useState([]);
  const [formData, setFormData] = useState({});
  const [resultValue, setResultValue] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [operatorList, setOperatorList] = useState([]);
  const [operator, setOperator] = useState('');
  const [scope,setScope] = useState({})
  const [showResult,setShowResult] = useState(true)

  useEffect(()=>{
    const {markets, result} = getLocalData('scope')
    setScope({markets, result})
  },[]);

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData({ ...formData, end_date: date });
  };

  useEffect(() => {
    async function getSport() {
      let res = await getCaller(`api/v1/bookmakers/findByIdBookMaker?id=${userName.market_id}`)
      const responseData = res.data[0]
      setFormData({ ...responseData });

      let runners = typeof responseData.runners !== 'object' ? JSON.parse(responseData.runners) : responseData.runners;
      setRunners(runners)
    }
    getSport();
  }, [userName.market_id, userName.match_id]);

  const handleSubmit = async () => {
    try {
      const res = await updateCaller(`api/v1/bookmakers/${userName.market_id}`, {
        runners: runners,
        bet_delay: formData.bet_delay,
        is_com: formData.is_com,
        max_market_limit: formData.max_market_limit,
        msg: formData.msg,
        is_active: formData.is_active,
        stake_limit: formData.stake_limit,
        is_sus: formData.is_sus,
        market_name: formData.market_name,
        sus_time: userName.sus_time,
        end_date : formData.end_date,
        is_auto: formData.is_auto,
        is_toss: formData.market_name === "Toss" ? 1 : 0
      });
      if (res.success === true) {
        showToast("Bookmaker updated Successfully");
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const updateRunnerSelectionStatus = (si, value) => {
    const newRunners = [...runners];
    const runnerIndex = newRunners.findIndex(r => r.si === si);

    if (runnerIndex > -1) {
      // newRunners[runnerIndex] = { ...newRunners[runnerIndex], ss: !value ? 'ACTIVE' : 'SUSPEND' };

      const newStatus = value ? "SUSPEND" : "ACTIVE";
      newRunners[runnerIndex] = { ...newRunners[runnerIndex], ss: newStatus };
      const message = newStatus === "SUSPEND" ? "Suspend Enabled" : "Suspend Disable";
      showToast(message);
    }
    setRunners(newRunners);
  };

  const updateRunner = (si, value, type) => {
    const newRunners = [...runners];
    const runnerIndex = newRunners.findIndex(r => r.si === si);

    if (runnerIndex > -1) {
      newRunners[runnerIndex] = { ...newRunners[runnerIndex], [type]: value };
    }
    setRunners(newRunners);
  };
  
  const handleBackChange = (si, value) => {
    const newRunners = [...runners];
    const runnerIndex = newRunners.findIndex((r) => r.si === si);
    value.replace(/[^0-9]/g, '');
    if (runnerIndex > -1) {
      const parsedValue = value;
      if(parsedValue > 110){
        showToast(`Back Value should be less then 110`,true);
        return
      }
      if ( parsedValue >=0) {
        newRunners[runnerIndex] = {
          ...newRunners[runnerIndex],
          b: parsedValue,
          l: newRunners[runnerIndex]?.o
            ? parsedValue + newRunners[runnerIndex].o
            : newRunners[runnerIndex]?.l
        };
      }
    }
    
    if (value === '00') {
      newRunners[runnerIndex].ss = 'SUSPEND';
    }
    setRunners(newRunners);
  };

  const handleLayChange = (si, value) => {
    const newRunners = [...runners];
    const runnerIndex = newRunners.findIndex((r) => r.si === si);
    value.replace(/[^0-9]/g, '');
    if (runnerIndex > -1) {
      const parsedValue = value;
      if(parsedValue > 110){
        showToast(`Lay Value should be less then 110`,true);
        return
      }
      if ( parsedValue >= 0) {
        newRunners[runnerIndex] = {
          ...newRunners[runnerIndex],
          l: parsedValue,
          b: newRunners[runnerIndex]?.o 
            ? Math.max(parsedValue - newRunners[runnerIndex].o, 0) 
            : newRunners[runnerIndex]?.b       
       };
      }
  
    }
    if (value === '00') {
      newRunners[runnerIndex].ss = 'SUSPEND';
    }
    setRunners(newRunners);
  };

  const handleBookmakerResult = (e) => {
   setShowResult(false);
    setResultValue(e.value);

    if(e?.value){
      const initialOperator  = operatorList?.map(el => ({ value: el, label: el }));
      setOperator(initialOperator);
    }
  };

  useEffect(() => {
    const getData = async() => {
      const response = await getCaller(`api/v1/results/operatorList`);
      const fetchedOperatorList = response?.data?.operatorList;
      setOperatorList(fetchedOperatorList);
    }
    getData();
  },[]);
 
  const options = [{ value: 'all', label: 'Select All' }, ...operatorList.map((el) => ({ value: el, label: el }))];

  const handleOperator = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      if (selectedOptions.length === options.length) {
        setOperator([]);
      } else {
        setOperator(operatorList.map(el => ({ value: el, label: el })));
      }
    } else {
      setOperator(selectedOptions);
    }
  };

  const getOptions = (selectedOptions) => {
    if (selectedOptions?.length === operatorList.length) {
      return operatorList.map((el) => ({ value: el, label: el }));
    }
    return options;
  };

  async function onSubmit(){
    let value = JSON.parse(resultValue);
    let postdata = {
      bookmaker_id: userName.market_id,
      market_name: userName.market_type,
      match_id: userName.match_id,
      result: value,
      selection: value.sln ?? '',
      sport_id: userName.sport_id
    }
    await showConfirmationDialog(`You want to Submit this Result (${userName.market_type})`)
    .then(async (result) => {
      if (result.isConfirmed) {
        const response = await postCaller(`api/v1/results`, postdata);
        if (response.success) {
          showSuccessMessage(response.message)
            window.location.reload();
        } else {
          showToast(response.message, true);
        }
      }
    })
  };

  const handleMarketNameChange = (value ) => {
   setFormData({...formData , market_name: value})
   if(value === 'Toss'){
    const filteredRunners = runners.filter(runner => runner.sln !== "The Draw");
    setRunners(filteredRunners)
   }
  }
  
  return (
    <div className='book-maker-market'>
      <div className="book-maker-heading">
        <p className='book-result-name'> BOOKMAKER RESULT DECLARE </p>
        <div className='remove-market-container'></div>
      </div>

      <div className="book-maker-content">
        <div className="book-maker-select">
          <label htmlFor="fancy-results"> Select bookmaker </label> <br />
          <select name="" id="fancy-results" className='fancy-result-input' style={{marginTop: '-10px'}} >
            <option value=""> {userName.market_type} </option>
          </select>
        </div>

        <div className="book-maker-select">
          <label htmlFor="book-mker-result"> Result </label> <br />
            <Select id="book-mker-result" onChange={(selectedOption) => handleBookmakerResult(selectedOption)}
              options={runners?.length > 0 ? [
                ...runners?.map((el) => ({ value: JSON.stringify(el), label: el.sln })),
                { value: JSON.stringify({ 'sln': 'tie', 'si': 'tie' }), label: 'Tie' },
                { value: JSON.stringify({ 'sln': 'abandoned', 'si': 'abandoned' }), label: 'Abandoned' },
              ] : []}
              placeholder="Select Result"
              styles={ selectStyles }
            />
        </div>

        <div className='book-maker-select'>
          <label>Select Operators</label><br />
          <Select onChange={handleOperator} options={getOptions(operator)} value={operator} placeholder="Select Operators" isMulti />
        </div>

        <div className='book-maker-btn' style={{ marginTop: "1.5rem" }}>
          <Button type='submit' disabled={!scope?.result?.add || showResult} name='Submit Result' className='submit' onClick={onSubmit} />
        </div>
      </div>
       
      <div className="market-match-container">
        <div className="book-maker-match">
          <div className='span-book'><p> BOOKMAKER MARKET </p><span className='match-name' >{userName.event_name} </span> </div>
          <div className="book-input-submit-1"> <div className='book-input'></div></div>
        </div>
        
        {allowAddBookmaker ?
          <div>
            {
              <div>
                <div className="market-book-maker-border">
                  <div className='market-bookmaker-popup' style={{ overflowX: "auto" }}>
                    <div className="">
                      <label htmlFor="marketName">Market Name</label> <br />
                        <input type="text" autoComplete="off" name="marketName" className='odd-input' placeholder='Market name' value={formData?.market_name?.charAt(0).toUpperCase() + formData?.market_name?.slice(1)} onChange={(e) => handleMarketNameChange(e.target.value)} disabled={ formData?.market_name === 'Toss'} /> <br />
                      
                      <label htmlFor="stake_limit">Stake Limit</label> <br />
                        <input type="number" autoComplete="off" name="stake_limit" className='odd-input' placeholder='Stake limit' value={formData.stake_limit ?? ""} onChange={(e) => { const value = parseFloat(e.target.value);
                        if (!isNaN(value) && value >= 0) { setFormData({ ...formData, stake_limit: value }) }}} /> <br />
                      
                      <label htmlFor="msg">Message</label> <br />
                        <input type="text" autoComplete="off" name="msg" className='odd-input' id="" placeholder='Message' value={formData.msg ?? ""} onChange={(e) => setFormData({ ...formData, msg: e.target.value })} /> <br />
                      
                      <label htmlFor="max_market_limit">Max Market Limit</label> <br />
                        <input type="number" autoComplete="off" name="max_market_limit" className='odd-input' id="" placeholder='Max market limit' value={formData.max_market_limit ?? ""} onChange={(e) => {  const value = parseFloat(e.target.value);
                          if (!isNaN(value) && value >= 0) { setFormData({ ...formData, max_market_limit: value })}}} /> <br />
                      
                      <label htmlFor="bet_delay">Bet delay</label> <br />
                        <input type="number" autoComplete="off" name="bet_delay" className='odd-input' placeholder='Bet delay' value={formData.bet_delay ?? ""} onChange={(e) => {  const value = parseFloat(e.target.value);
                          if (!isNaN(value) && value >= 0) { setFormData({ ...formData, bet_delay: value })}}} /><br />
                      
                      <div>
                        <label htmlFor="end_date"> End Date </label> <br />
                        <DatePicker
                          id="end_date"
                          name="end_date"
                          selected={endDate}
                          onChange={handleEndDateChange}
                          showTimeSelect
                          timeFormat="HH:mm"
                          placeholderText="End Date & Time"
                          dateFormat="yyyy-MM-dd HH:mm:ss"
                          className='fancy-stake-input'
                          value={formData?.end_date != null ? moment(formData.end_date).format('YYYY-MM-DD HH:mm:ss') : ""}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        /><br />
                      </div>
                    </div>

                    <div>
                      <div className="suspend-container">
                        <div className="suspend-container-body"><p className='suspend-para'>Auto</p><ToogleButton defaultChecked={Boolean(formData.is_auto)} value={formData.is_auto} 
                          onChange={(e) => setFormData({ ...formData, is_auto: e ? 1 : 0 })} disabled={true} />
                        </div>
                        
                        <div className="suspend-container-body"><p className='suspend-para'>Suspend</p><ToogleButton defaultChecked={Boolean(formData.is_sus)} value={formData.is_sus} 
                          onChange={(e) => {
                            const newValue = e ? 1 : 0;
                            setFormData({ ...formData, is_sus: newValue });
                            showToast(newValue ? "Suspend Enabled" : "Suspend Disabled");
                          }} />
                        </div>

                        <div className="suspend-container-body"><p className='suspend-para'>Commission</p><ToogleButton defaultChecked={Boolean(formData.is_com)} value={formData.is_com}
                          onChange={(e) => {
                            const newValue = e ? 1 : 0;
                            setFormData({ ...formData, is_com: e ? 1 : 0 });
                            showToast(newValue ? "Commission Enabled" : "Commission Disabled");
                          }} />
                        </div>
                        
                        <div className="suspend-container-body"><p className='suspend-para'>Active</p><ToogleButton defaultChecked={Boolean(formData.is_active)} value={formData.is_active}
                          onChange={(e) => {
                            const newValue = e ? 1 : 0;
                            setFormData({ ...formData, is_active: newValue });
                            showToast(newValue ? "Active Enabled" : "Active Disabled");
                          }} />
                        </div>
                      </div>
                      <div className=""></div>
                    </div>
                  </div>
                </div>

                <div className="bookmaker-table-container" style={{ overflowX: "auto" }}>
                  <table width="100%">
                    <thead id='fancy-head-coloumn'>
                      <tr className=''>
                        <th align='left'> TEAM NAME </th>
                        <th align='left'> BACK </th>
                        <th align='left'> LAY </th>
                        <th align='left'> SUSPEND </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        runners?.map((runner, index) => (
                          <tr className='fancy-head-row' key={runner.si}>
                            <td> <input type="text" autoComplete="off" name="selectionName" className='book-maker-column-input-1' placeholder='Team name' value={runner.sln ?? ""} onChange={(event) => { updateRunner(runner.si, event.target.value, 'sln') }} /> </td>
                            <td> <input type="number" autoComplete="off" name="backOdds" className='book-maker-column-input' placeholder='Back' value={formData.is_auto === 1 ? 0 : formData.market_name === 'Toss' ? 98 : (runner.b ?? "")} onChange={(event) => { handleBackChange(runner.si, event.target.value) }} disabled={formData.is_auto === 1 || formData.market_name === 'Toss'} /> </td>
                            <td> <input type="number" autoComplete="off" name="layOdds" className='book-maker-column-input' placeholder='Lay' value={formData.is_auto === 1 ? 0 :  formData.market_name === 'Toss' ? 0 :(runner.l ?? "")} onChange={(event) => { handleLayChange(runner.si, event.target.value) }} disabled={formData.is_auto === 1 || formData.market_name === 'Toss'} /> </td>
                            <td align='left' > <ToogleButton defaultChecked={runner.ss === 'SUSPEND'} onChange={(value) => { updateRunnerSelectionStatus(runner.si, value) }} /> </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>

                  <div className='bookmaker-btn'>
                    <Button className='submit' name='Update' disabled={!scope?.markets?.update} type="button" onClick={handleSubmit} />
                  </div>
                </div>
              </div>
            }
          </div>
          : null}
      </div>
    </div>
  )
};

export default BookmakerAssignMarket;